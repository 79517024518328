import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { toast } from "react-toastify";

import { Cut } from "components/Cut";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { Spinner2 } from "components/Spinner2";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Body } from "layout/Body";
import { PageHeader } from "components/PageHeader";
import { MainBlock } from "layout/MainBlock";
import { getPaymentShedule } from "services/receivablesApi";
import { dateFormat, priceFormat } from "utils/format";
import { RightSidebar } from "layout/RightSidebar";

import styles from "./peymentShedule.module.scss";

type TReceivableRow = {
  id: string;
  number: number;
  shippingDate: string;
  contract: string;
  summ: number;
  paymentDate: string;
  remainingDebt: string;
  remainingDaysExpired: string;
  delayDayCount: number;
};

export const PaymentShedulePage: FC = () => {
  const { breakpoint, isDesktop, isMobile, isTablet } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [shedules, setShedules] = useState([]);

  const getAndSetPaymentShedule = (refresh?: boolean) => {
    setLoading(true);
    getPaymentShedule()
      .then((res) => {
        setShedules(res.orders);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetPaymentShedule();
  }, []);

  const Receivable = ({ row }: { row: TReceivableRow }) => {
    if (isDesktop) {
      return <div className={classNames(styles.sheduleItem,
        { [styles.expired]: parseInt(row.remainingDaysExpired) > 0 }
      )} key={row.id} >
        <div>{row.number}</div>
        <div>{dateFormat(row.shippingDate)}</div>
        <div><Cut>{row.contract}</Cut></div>
        <div>{priceFormat(row.summ)}</div>
        <div>{priceFormat(row.remainingDebt)}</div>
        <div>{row.delayDayCount}</div>
        <div>{dateFormat(row.paymentDate)}</div>
        <div> {row.remainingDaysExpired}</div>
      </div >;
    }

    return <div
      className={styles.sheduleItemMobile}
    >
      <div className={styles.top}>

      </div>
      <div className={styles.bottom}>

      </div>
    </div>;
  };

  return <>
    <BreadCrumbs data={[{ title: "График платежей", link: "/paymentShedule" }]} />
    <Helmet>
      <title>График платежей</title>
    </Helmet>
    <div className={classNames(styles.shedule, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}

      <Body>
        <PageHeader title="График платежей" className={styles.pageHeader} />

        <MainBlock>
          {isDesktop && (
            <div className={styles.shedulesHead}>
              <div>№ Документа</div>
              <div>Дата отгрузки</div>
              <div>Договор</div>
              <div>Сумма по реализации</div>
              <div>Сумма долга по реализации</div>
              <div>Дней отсрочки</div>
              <div>Оплатить до</div>
              <div>Просрочено дней</div>
            </div>
          )}

          {shedules && shedules.map((row: TReceivableRow) => (
            <Receivable row={row} key={row.id} />
          ))}

        </MainBlock>
      </Body>
      {!isMobile && <RightSidebar />}
    </div >
  </>;
};
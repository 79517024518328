import { FC, useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { useBreakpoint } from "hooks/useBreakpoint";
import { ReactComponent as HeartIcon } from "assets/img/svg/heart.svg";
import { useUser } from "context/UserContext";
import { cut } from "utils/cut";
import { priceFormat } from "utils/format";
import { BottomNavbar } from "components/BottomNavbar";

import logo from "assets/img/svg/logo.svg";
import { ReactComponent as UserIcon } from "assets/img/svg/user2.svg";

import { SearchField } from "./SearchField";
import { MiniCart } from "./MiniCart";
import styles from "./header.module.scss";


interface IHeader {
  setShownMenu: (shownMenu: boolean | ((prev: boolean) => boolean)) => void;
  shownMenu: boolean;
}

export const Header: FC<IHeader> = ({ setShownMenu, shownMenu }) => {
  const [shownUser, setShownUser] = useState(false);
  const { breakpoint, isMobile, isTablet, isDesktop } = useBreakpoint();
  const { user, setUser, setAuthorized } = useUser();
  const navigate = useNavigate();

  const logout = () => {
    setAuthorized(false);
    localStorage.removeItem("clientAccessToken");
    localStorage.removeItem("clientRefreshToken");

    setUser({
      addresses: [],
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      email: "",
      type: 0,
      markup: 0,
      delayDayCount: 0,
      creditSumMax: 0,
      orgNameShort: "",
      balance: 0
    });

    navigate({ pathname: "/login" });
  };

  const InfoBlock = () => (
    <>
      {user.type === 2 &&
        <div className={styles.info}>
          <div className={styles.info_title}>Название компании</div>
          <div className={styles.info_value}>{cut(user.orgNameShort, 8, 8)}</div>
        </div>
      }
      <div className={styles.info}>
        <div className={styles.info_title}>Пользователь</div>
        <div className={styles.info_value}>{cut(user.firstName, 8, 8)}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.info_title}>Баланс</div>
        <div className={styles.info_value}>{priceFormat(user.balance)} р.</div>
      </div>
      {user.overdueDebt > 0 &&
        <div className={styles.info}>
          <Link to="/paymentShedule">
            <div className={styles.info_title}>Просроченный долг</div>
            <div className={classNames(styles.info_value, styles.overdueDebt)}>{priceFormat(user.overdueDebt)} р.</div>
          </Link>
        </div>
      }
      <div className={styles.info}>
        <div className={styles.info_title}>Бонусы</div>
        <div className={styles.info_value}>{priceFormat(user?.bonus)}</div>
      </div>
    </>
  );

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      {isDesktop && (
        <>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="ЛИДЕР ТРАК" />
          </Link>

          {user && (
            <>
              <button
                className={styles.menuButton}
                onClick={() => setShownMenu((prev: boolean) => !prev)}
              >
                Меню
              </button>
              <SearchField />
              <button className={styles.userButton}>
                <UserIcon />
                <div className={styles.infoBlockPopup}>
                  <UserIcon />
                  <InfoBlock />
                </div>
              </button>
              <div className={styles.infoBlock}>
                <InfoBlock />
              </div>
              <MiniCart />
              <Link to="/favorite" className={styles.favorite}>
                <HeartIcon />
              </Link>
              <div className={styles.exit}>
                <button onClick={logout}>Выход</button>
              </div>
            </>
          )}
        </>
      )}

      {!isDesktop && (
        <>
          <div className={styles.top}>
            <div className={styles.leftBlock}>
              <button
                className={styles.menuButtonMobile}
                onClick={() => setShownMenu((prev: boolean) => !prev)}
              >
                <div
                  className={classNames(styles.hamburger, {
                    [styles.shown]: shownMenu
                  })}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </button>
              <Link to="/" className={styles.logo}>
                <img src={logo} alt="ЛИДЕР ТРАК" />
              </Link>
            </div>
            <div className={styles.rightBlock}>
              <Link to="/favorite" className={styles.favorite}>
                <HeartIcon />
              </Link>
              <button
                className={styles.userButton}
                onClick={() => setShownUser((prev: boolean) => !prev)}
              >
                <UserIcon />
              </button>
              <MiniCart />
            </div>
          </div>
          <SearchField />
          <BottomNavbar
            title="Информация о пользователе"
            shown={shownUser}
            setShown={setShownUser}
          >
            <div className={styles.userInfo}>
              <InfoBlock />
              <div className={styles.exit}>
                <button onClick={logout}>Выход</button>
              </div>
            </div>
          </BottomNavbar>
        </>
      )}
    </div>
  );
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./mainBlock.module.scss";

interface IMainBlock {
  children: ReactNode;
  className?: string;
}

export const MainBlock: FC<IMainBlock> = ({ children, className }) => {
  const { breakpoint } = useBreakpoint();
  return (
    <div
      className={classNames(styles.mainBlock, styles[breakpoint], className)}
    >
      {children}
    </div>
  );
};

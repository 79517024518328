import {
  FC,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  KeyboardEvent
} from "react";
import { useBreakpoint } from "hooks/useBreakpoint";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { searchSlice } from "store/reducers/SearchSlice";

import { Button } from "components/Button";
import { IQuery, suggestion } from "services/priceApi";
import useQuery from "hooks/useQuery";
import { useSearch } from "context/SearchContext";
import { useUser } from "context/UserContext";
import { Spinner2 } from "components/Spinner2";
import { fetchSearch } from "store/reducers/ActionCreators";

import styles from "./searchField.module.scss";

import { ReactComponent as SearchWhiteIcon } from "./searchWhite.svg";

export const SearchField: FC = () => {
  const query = useQuery(useLocation);
  const location = useLocation();
  const autocompleteEl = useRef(null);
  const { user } = useUser();
  const { breakpoint, isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [shownSuggestions, setShownSuggestions] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const dispatch = useAppDispatch();

  const { article, brand } = useAppSelector(state => state.searchReducer);

  const searchHandler = (article: string, brand?: string | null) => {
    if (!article) return;
    if (!brand) {
      dispatch(searchSlice.actions.setBrand(""));
    } else {
      dispatch(searchSlice.actions.setBrand(brand));
    }
    dispatch(searchSlice.actions.setArticle(article));
    dispatch(fetchSearch({ article, brand, userId: user.id }));
    setShownSuggestions(false);
  };

  useEffect(() => {

    if (!article) {
      if (location.search && location.pathname.includes("search")) {
        navigate({ pathname: "/search" });
      }
      return;
    };

    const url = new URLSearchParams();
    if (article) {
      url.append("article", article);
    }
    if (brand) {
      url.append("brand", brand);
    }

    setSearch(article);

    if (url.toString()) {
      navigate({ pathname: "/search", search: `?${url}` });
    } else {
      navigate({ pathname: "/search" });
    }
  }, [article, brand]);

  useEffect(() => {
    const article = query.get("article") || "";
    const brand = query.get("brand") || "";

    if (article) {
      dispatch(searchSlice.actions.setArticle(article));
      setSearch(article);
    }

    if (brand) {
      dispatch(searchSlice.actions.setBrand(brand));
    }

    if (!user.id) return;

    if (article.length > 2 && brand.length > 1) {
      dispatch(fetchSearch({ article, brand, userId: user.id }));
      setShownSuggestions(false);
    }

  }, [user.id]);

  const cleanSearch = () => {
    dispatch(searchSlice.actions.cleanResult());
    dispatch(searchSlice.actions.setArticle(""));
    dispatch(searchSlice.actions.setBrand(""));
    setSearch("");
    setSuggestions([]);
    setShownSuggestions(false);
  };

  const autocompleteHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setAutocompleteLoading(true);
    setSearch(e.target.value.trim());

    if (e.target.value.length < 2) {
      setSuggestions([]);
      setShownSuggestions(false);
      return;
    }

    setTypingTimeout(
      setTimeout(() => {
        suggestion({ search: e.target.value })
          .then((res) => {
            setSuggestions(Object.values(res));
            setShownSuggestions(true);
          })
          .finally(() => setAutocompleteLoading(false));
      }, 300)
    );
  };

  useEffect(() => {
    const closeResult = ({ target }: MouseEvent): void => {
      if (
        !autocompleteEl?.current ||
        !(autocompleteEl?.current as Node).contains(target as Node)
      ) {
        setShownSuggestions(false);
      }
    };
    document.addEventListener("click", closeResult);
    return () => document.removeEventListener("click", closeResult);
  }, []);

  useEffect(() => {
    if (suggestions.length > 0) {
      setShownSuggestions(true);
    } else {
      setShownSuggestions(false);
    }
  }, [suggestions]);

  const onClick = (article: string, brand: string) => {
    setSearch(article);
    dispatch(searchSlice.actions.setArticle(article));
    dispatch(searchSlice.actions.setBrand(brand));
    dispatch(fetchSearch({ article, brand, userId: user.id }));
    setShownSuggestions(false);
  };

  const focusHandler = () => {
    if (!suggestions.length) return;
    setShownSuggestions(true);
  };

  const onEnterPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (search.length < 2) return;
      dispatch(searchSlice.actions.setArticle((e.target as HTMLInputElement).value));
      dispatch(fetchSearch({ article, brand, userId: user.id }));
      setShownSuggestions(false);
    }
  };

  return (
    <div
      className={classNames(styles.wrapper, styles[breakpoint])}
      ref={autocompleteEl}
    >
      <input
        className={styles.searchField}
        value={search}
        onChange={autocompleteHandler}
        maxLength={128}
        onFocus={focusHandler}
        onKeyPress={onEnterPressHandler}
      />
      {shownSuggestions && (
        <div className={styles.suggestions}>
          {suggestions.map(
            (res: {
              brand: string;
              name: string;
              partNumber: string;
              value: number;
            }) => (
              <div
                key={`${res.partNumber}${res.brand}`}
                className={styles.suggestionItem}
                dangerouslySetInnerHTML={{
                  __html: `<span>${res.partNumber.replace(
                    search,
                    `<strong>${search}</strong>`
                  )}</span> ${res.brand} ${res.name}`
                }}
                onClick={() => onClick(res.partNumber, res.brand)}
              />
            )
          )}
        </div>
      )}
      <div className={styles.rightBlock}>
        {autocompleteLoading ?
          <Spinner2 size={24} className={styles.spinner} />
          :
          <>{search &&
            <button onClick={cleanSearch} className={styles.cleanSearch} />
          }</>}

        <Button
          onClick={() => searchHandler(search)}
          className={styles.searchButton}
          breakpoint={breakpoint}
        >
          {isMobile ? <SearchWhiteIcon /> : "Поиск"}
        </Button>
      </div>
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import { useUser } from "context/UserContext";
import { dateFormat } from "utils/format";
import { useBreakpoint } from "hooks/useBreakpoint";

import { ReactComponent as RemoveIcon } from "assets/img/svg/trash.svg";

import { ReactComponent as DownloadIcon } from "./download.svg";
import styles from "./contracts.module.scss";

type TContract = {
  id: string;
  date: string;
  main: boolean;
  name: string;
  number: string;
  file?: string;
};

export const Contracts: FC = () => {
  const { breakpoint } = useBreakpoint();
  const { user } = useUser();

  return (
    <div className={classNames(styles[breakpoint])}>
      <div className={styles.title}>Договоры</div>
      <div className={styles.subtitle}>
        Вы можете скачать договор в любое время
      </div>

      <div>
        {user?.contracts?.map((contract: TContract) => (
          <div className={styles.contractItem} key={contract.id}>
            <div>
              <div className={styles.contractText}>{contract.name}</div>
              <div className={styles.date}>
                <span>от:</span> {dateFormat(contract.date)}
              </div>
            </div>

            <div>
              {contract.file &&
                <a
                  className={styles.download}
                  href={`${process.env.REACT_APP_BASE_URL}${contract.file}`}
                >
                  <DownloadIcon />
                </a>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

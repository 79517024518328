import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import classNames from "classnames";
import { toast } from "react-toastify";

import { Card } from "components/Card";
import { getWindowDimensions, useBreakpoint } from "hooks/useBreakpoint";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";
import { TOrderRow } from "pages/Orders";
import { getOrders } from "services/orderApi";
import { dateFormat, priceFormat } from "utils/format";
import { Spinner2 } from "components/Spinner2";

import styles from "./orders.module.scss";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


const COUNT_ORDERS = 20;

export const Orders: FC = () => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const { breakpoint, isDesktop } = useBreakpoint();
  const [slidePerWiew, setSlidePerWiew] = useState(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [orders, setOrders] = useState<TOrderRow[]>([]);
  const [loading, setLoading] = useState(true);

  const getAndSetOrders = () => {
    getOrders({
      paginationCount: COUNT_ORDERS,
      paginationOffset: 0,
      activeOrders: true
    })
      .then((res) => {
        setOrders(res.items);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetOrders();
  }, []);

  useEffect(() => {

    function handleResize() {
      const { width } = getWindowDimensions();

      if (breakpoint === "desktop") {
        setSlidePerWiew((width - 695) / 350);
      }

      if (breakpoint === "tablet") {
        setSlidePerWiew((width - 300) / 350);
      }

      if (breakpoint === "mobile") {
        setSlidePerWiew(width / 353);
      }
    }
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  useEffect(() => {
    if (swiper !== null) {
      setTimeout(() => swiper.slideTo(0), 100);
    }
  }, [swiper]);

  return <div className={classNames(styles.orders, styles[breakpoint])}>
    <div className={styles.top}>
      <div className={styles.title}>Заказы</div>
      <Link to="/orders">Все заказы</Link>
    </div>

    <div className={styles.swiperWrapper}>
      {loading && <Spinner2 overlay />}
      <Swiper
        spaceBetween={8}
        slidesPerView={slidePerWiew}
        className={styles.caroucel}
        modules={[Navigation, Thumbs]}
        centeredSlides={breakpoint === "mobile"}
        onInit={(swiper: SwiperCore): void => {
          if (typeof swiper.params.navigation !== "boolean") {
            const navigation = swiper.params.navigation;
            if (navigation) {
              navigation.prevEl = navigationPrevRef.current;
              navigation.nextEl = navigationNextRef.current;
            }
          } else {
            swiper.params.navigation = false;
          }
          swiper.navigation.init();
          swiper.navigation.update();
          setSwiper(swiper);
        }}
      >
        {isDesktop &&
          <div className={styles.prevButton} ref={navigationPrevRef}><ArrowIcon /></div>
        }
        <div className={styles.slides}>
          {orders.map(item =>
            <SwiperSlide key={item.id} className={styles.slide}>
              <Card className={styles.order}>
                <div className={styles.orderTop}>
                  <div className={styles.orderStatus}>
                    <div className={styles.package} />
                    Активный заказ
                  </div>
                  <Link to={`/orders/${item.id}`}
                    className={styles.orderLink}>Показать <div className={styles.document} /></Link>
                </div>
                <div className={styles.price}>
                  {priceFormat(item.summ)} ₽
                </div>
                <div className={styles.bottom}>
                  <div className={styles.number}>
                    № заказа
                    <span>{item.number}</span>
                  </div>
                  <div className={styles.date}>
                    Дата заказа
                    <span>{dateFormat(item.date)}</span>
                  </div>
                  <div className={styles.status}>
                    <div style={{
                      backgroundColor: item.status?.color
                    }}>{item.status?.nameForCustomer}</div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>)}
        </div>
        {isDesktop &&
          <div className={styles.nextButton} ref={navigationNextRef}><ArrowIcon /></div>
        }
      </Swiper>
    </div>
  </div >;
};
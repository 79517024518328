import {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  ReactElement
} from "react";
import { RModal } from "components/RModal";

interface Imodal {
  children: ReactNode;
  className?: string;
}

export const useModal: () => [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  ({ children, className }: Imodal) => ReactElement<typeof RModal>
] = () => {
  const [shown, setShown] = useState(false);

  const Modal = ({ children, className }: Imodal) => (
    <RModal shown={shown} setShown={setShown} className={className}>
      {children}
    </RModal>
  );

  return [shown, setShown, Modal];
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./pageHeader.module.scss";

interface IPageHeader {
  children?: ReactNode | null;
  title: string;
  className?: string;
}

export const PageHeader: FC<IPageHeader> = ({ children, title, className }) => {
  const { breakpoint } = useBreakpoint();
  return (
    <div className={classNames(styles.header, styles[breakpoint])}>
      <div className={styles.title}>{title}</div>
      <div className={classNames(styles.content, className)}>{children}</div>
    </div>
  );
};

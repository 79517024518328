import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { useUser } from "context/UserContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Spinner2 } from "components/Spinner2";
import { RightSidebar } from "layout/RightSidebar";

import { Orders } from "./Orders";
import { BalanceWidgeet } from "./BalanceWidget";
import { Payments } from "./Payments";
import { HistorySearch } from "./HistorySearch";
import { BalanceCard } from "./BalanceCard";
import { Banners } from "./Banners";
import { BonusCard } from "./BonusCard";
import { FavoriteCard } from "./FavoriteCard";
import styles from "./home.module.scss";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { ActionsWidget } from "./ActionsWidget";

export const HomePage: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { breakpoint, isTablet, isDesktop, isMobile } = useBreakpoint();
  const { user, setUser, setAuthorized } = useUser();

  const logout = () => {
    setAuthorized(false);
    localStorage.removeItem("clientAccessToken");
    localStorage.removeItem("clientRefreshToken");

    setUser({
      addresses: [],
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      email: "",
      type: 0,
      markup: 0,
      delayDayCount: 0,
      creditSumMax: 0,
      orgNameShort: "",
      balance: 0,
    });

    navigate({ pathname: "/login" });
  };


  return <>
    <Helmet>
      <title>
        Домашняя страница
      </title>
    </Helmet>
    <div className={classNames(styles.home, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}

      {isDesktop &&
        <div className={styles.leftSide}>

          <BalanceCard balance={user.balance} overdueDebt={user.overdueDebt} />

          <BonusCard />

          <FavoriteCard />

          <div className={styles.menu}>
            <Link to={"/profile"} className={styles.menuItem}>
              <div className={styles.settings} />
              Настройки
            </Link>
            <Link to={""} className={styles.menuItem}>
              <div className={styles.message} />
              Связаться с нами
            </Link>
            <button onClick={logout} className={styles.menuItem}>
              <div className={styles.logout} />
              Выйти
            </button>
          </div>
        </div>
      }
      <div className={styles.center}>

        <div className={styles.titleBlock}>
          <div className={styles.title}>Добрый день, {user.firstName}!</div>
          <div className={styles.subtitle}>Компания: {user.name}</div>
        </div>

        {isTablet &&
          <div className={styles.cards}>
            <div>
              <BalanceCard balance={user.balance} overdueDebt={user.overdueDebt} />
              <FavoriteCard />
            </div>
            <div>
              <BonusCard />
            </div>
          </div>
        }

        {isMobile &&
          <div className={styles.cards}>
            <div className={styles.top}>
              <BalanceCard balance={user.balance} overdueDebt={user.overdueDebt} />
            </div>
            <div className={styles.bottom}>
              <FavoriteCard />
            </div>
          </div>
        }

        <Banners />

        <Orders />

        {isDesktop &&
          <BalanceWidgeet />
        }

        {isDesktop &&
          <ActionsWidget />
        }

        {/* <HistorySearch /> */}

      </div>
      {!isMobile && <RightSidebar />}
    </div >
  </>
};

import { FC } from "react";
import { Link } from "react-router-dom";

import { Card } from "components/Card";

import styles from "./actionsWidget.module.scss";

export const ActionsWidget: FC = () => {
  return <div>
    <div className={styles.top}>
      <div className={styles.title}>Счета и платежи</div>
    </div>

    <div className={styles.wrapper}>
      <Card className={styles.card}>
        <Link to="/reconciliationAct">
          <div className={styles.fileText} />
          <div>
            <div className={styles.title}>Заказать акт сверки</div>
            <div className={styles.content}>
              Отправьте заявку на получение акта сверки
            </div>
          </div>
        </Link>
      </Card>
    </div>
  </div>;
};

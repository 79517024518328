import { TProduct } from "components/ProductInfo";
import { TCart } from "pages/Cart";
import { TFavorite } from "pages/Favorite";

export const count = (cart: TCart[]) => {
  return cart.reduce((acc: number, val: TCart) => {
    return acc + val.count;
  }, 0);
};

export const summ = (cart: TCart[]) => {
  return cart.reduce((acc: number, val: TCart) => {
    return acc + val.price * val.count;
  }, 0);
};

export const inCart = (
  cart: TCart[],
  article: string,
  brand: string,
  providerId: string,
  priceListId?: string
) => {
  const cartItem = cart.find((item: TCart) => {
    if (item.priceListId && priceListId) {
      if (
        item.priceListId === priceListId &&
        item.provider?.id === providerId &&
        item.article === article &&
        item.brand === brand
      ) {
        return true;
      }
    } else {
      if (
        item.provider?.id === providerId &&
        item.article === article &&
        item.brand === brand
      ) {
        return true;
      }
    }

    return false;
  });

  return cartItem !== undefined ? cartItem.count : 0;
};

export const inFavorite = (favorite: TFavorite[], product: TProduct) => {
  const favoriteItem = favorite.find((item: TFavorite) => {
    if (item.nomenclature?.id === product.nomenclatureId) {
      return true;
    }
    return false;
  });
  return favoriteItem ? favoriteItem.id : null;
};
import { FC } from "react";
import classNames from "classnames";

import { useUser } from "context/UserContext";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./org.module.scss";

export const Org: FC = () => {
  const { user } = useUser();
  const { breakpoint, isMobile, isDesktop } = useBreakpoint();

  const RequisiteItem = ({
    title,
    value,
  }: {
    title: string;
    value: string;
  }) => {
    return (
      <div className={styles.reqisiteItem}>
        <div className={styles.reqisiteTitle}>{title}</div>
        <div className={styles.reqisiteValue}>{value}</div>
      </div>
    );
  };

  return (
    <div className={classNames(styles[breakpoint])}>
      <div className={styles.title}>Реквизиты организации</div>
      <div className={styles.subtitle}>Данные реквизиты будут использоваться в договоре и при выставлении счета</div>
      <div>
        <RequisiteItem title="Полное наименование" value={user.orgName} />
        <RequisiteItem title="Краткое наименование" value={user.orgNameShort} />
        <RequisiteItem
          title="ИНН / КПП"
          value={`${user.orgInn} / ${user.orgKpp}`}
        />
        <RequisiteItem title="Адрес организации" value={user.orgAddress} />
        <RequisiteItem title="ОГРН" value={user.orgOgrn} />
        <RequisiteItem title="ОКПО" value={user.orgOkpo} />
        <RequisiteItem title="БИК" value={user.orgBik} />
        <RequisiteItem title="Банк" value={user.orgBank} />
        <RequisiteItem title="Корр. сч." value={user.orgKs} />
        <RequisiteItem title="Расчетный сч." value={user.orgRs} />
        <RequisiteItem title="ФИО генерального директора" value={user.genDir} />
        *Для изменения реквизитов обратитесь к вашему менеджеру.
      </div>
    </div>
  );
};

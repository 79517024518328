import { FC } from "react";
import classNames from "classnames";

import { ReactComponent as SpinnerIcon } from "./spinner3.svg";

import styles from "./spinner.module.scss";

interface ISpinner3 {
  size?: number;
  className?: string;
}

export const Spinner3: FC<ISpinner3> = ({ size = 40, className }) => (
  <div className={classNames(styles.spinner, className)}>
    <SpinnerIcon />
  </div>
);

import { FC, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { activateUser } from "services/authApi";
import useQuery from "hooks/useQuery";
import { Spinner2 } from "components/Spinner2";

import styles from "./activateAccount.module.scss";

export const ActivateAccountPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const query = useQuery(useLocation);

  const code = query.get("code");

  useEffect(() => {
    if (!code) return;

    setLoading(true);
    activateUser({ code })
      .then((res) => setSuccess(true))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.activate}>
      <div className={styles.container}>
        {loading && <Spinner2 overlay />}
        <div className={styles.title}>Активация аккаунта</div>
        {success && (
          <div className={styles.success}>
            Аккаунт успешно активирован! <Link to="/login">Войти</Link>
          </div>
        )}
      </div>
    </div>
  );
};

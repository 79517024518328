import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";

import { PageHeader } from "components/PageHeader";
import classNames from "classnames";
import { useUser } from "context/UserContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { BreadCrumbs } from "layout/BreadCrumbs";

import { PersonalInfo } from "./PersonalInfo";
import { Org } from "./Org";
import { Addresses } from "./Addresses/Addresses";
import { Contracts } from "./Contracts";

import "swiper/css";

import styles from "./profile.module.scss";
import { Password } from "./Password";

const TABS_LEGAL = [
  { id: 1, title: "Персональная информация" },
  { id: 2, title: "Реквизиты" },
  { id: 3, title: "Адреса доставки" },
  { id: 4, title: "Договоры" },
  { id: 5, title: "Изменить пароль" },
];

const TABS_PRIVATE = [
  { id: 1, title: "Персональная информация" },
  { id: 3, title: "Адреса доставки" },
  { id: 5, title: "Изменить пароль" },
];

interface ITabButton {
  id: number;
  title: string;
}

export const ProfilePage: FC = () => {
  const { breakpoint, isDesktop, isMobile } = useBreakpoint();
  const [selected, setSelected] = useState(1);
  const { user } = useUser();
  
  const TabButton: FC<ITabButton> = ({ id, title }) => (
    <button
      className={classNames(styles.tabItem, {
        [styles.active]: selected === id,
      })}
      onClick={() => setSelected(id)}
    >
      {title}
    </button>
  );

  return (
    <>
      <BreadCrumbs data={[{ title: "Профиль" }]} />
      <Helmet>
        <title>Профиль</title>
      </Helmet>
      {isDesktop && <PageHeader title="Профиль" />}
      <div className={classNames(styles.profile, styles[breakpoint])}>
        <div className={styles.leftBlock}>
          <div className={styles.tabs}>
            {!isMobile ? (
              <>
                {(user.type === 2 ? TABS_LEGAL : TABS_PRIVATE).map((item) => (
                  <TabButton id={item.id} title={item.title} key={item.id} />
                ))}
              </>
            ) : (
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                className={styles.swiper}
              >
                {(user.type === 2 ? TABS_LEGAL : TABS_PRIVATE).map((item) => (
                  <SwiperSlide>
                    <TabButton id={item.id} title={item.title} key={item.id} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
        <div className={styles.centerBlock}>
          {selected === 1 && <PersonalInfo />}
          {selected === 2 && <Org />}
          {selected === 3 && <Addresses userAddresses={user?.addresses} />}
          {selected === 4 && <Contracts />}
          {selected === 5 && <Password />}
        </div>
        {isDesktop && <div className={styles.rightBlock}></div>}
      </div>
    </>
  );
};

import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Formik } from "formik";
import { object, string, setLocale } from "yup";

import { Input } from "components/Input";
import { useUser } from "context/UserContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Button } from "components/Button";
import { login, TLogin } from "services/authApi";
import { useCart } from "context/CartContext";
import { locale } from "validationLocale";
import { BreadCrumbs } from "layout/BreadCrumbs";

import { AuthWrapper } from "components/AuthWrapper";

import styles from "./login.module.scss";


setLocale(locale);

const LoginSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
});

export const LoginPage: FC<any> = () => {
  const { breakpoint, isMobile, isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const { setUser, setAuthorized } = useUser();
  const { setCart } = useCart();

  const loginHandler = (values: TLogin, resetForm: () => void) => {
    setLoading(true);

    setError("");

    login(values)
      .then((res) => {
        setAuthorized(true);
        setUser(res.user);
        setCart(res.cart);
        localStorage.setItem("clientAccessToken", res.accessToken);
        localStorage.setItem("clientRefreshToken", res.refreshToken);
        navigate({ pathname: "/" });
        window.scrollTo(0, 0);
        resetForm();
        setEmail("");
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          setError("Неверный логин или пароль");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BreadCrumbs
        data={[
          { title: "Авторизация" },
        ]}
      />
      <AuthWrapper>
        <div className={classNames(styles.login, styles[breakpoint])}>
          <div className={styles.title}>Авторизация</div>
          <div className={styles.subTitle}>Введите логин и пароль</div>
          <div className={styles.error}>{error}</div>

          <Formik
            initialValues={{
              email,
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { resetForm }) => {
              loginHandler(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={styles.input}
                  error={errors.email && touched.email ? errors.email : null}
                />
                <Input
                  id="password"
                  type="password"
                  label="Пароль"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className={styles.input}
                  showPass
                  error={
                    errors.password && touched.password ? errors.password : null
                  }
                />
                <div className={styles.rememberBlock}>
                  <Link to="/passwordRecovery" className={styles.passwordRecovery}>
                    Забыли пароль?
                  </Link>
                </div>
                <Button
                  type="submit"
                  className={styles.enter}
                  breakpoint={breakpoint}
                >
                  Вход
                </Button>
              </form>
            )}
          </Formik>
          <div className={styles.register}>
            <div>
              Нет аккаунта? - <Link to="/register">Регистрация</Link>
            </div>
          </div>
        </div>
      </AuthWrapper>

    </>







  );
};

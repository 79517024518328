import { FC } from "react";
import { useBreakpoint } from "hooks/useBreakpoint";
import classNames from "classnames";

import logo from "assets/img/svg/logo.svg";

import { Link, useLocation } from "react-router-dom";
import { ReactComponent as UserIcon } from "assets/img/svg/user2.svg";

import styles from "./headerWithoutAuth.module.scss";

export const HeaderWithoutAuth: FC = () => {
  const { breakpoint, isDesktop } = useBreakpoint();
  const location = useLocation();
  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      {isDesktop && (
        <>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="ЛИДЕР ТРАК" />
          </Link>
          <div className={styles.rightBlock}>
            {!location?.pathname?.includes('login') &&
              <Link to="/login" className={styles.login}>
                Войти
              </Link>
            }
          </div>
        </>
      )}

      {!isDesktop && (
        <>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="ЛИДЕР ТРАК" />
          </Link>
          {!location?.pathname?.includes('login') &&
            <Link to="/login" className={styles.login}><UserIcon /></Link>
          }

        </>
      )}
    </div>
  );
};

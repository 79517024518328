import { FC, useEffect, useState } from "react";
import classNames from "classnames";

import { Button } from "components/Button";
import { Transition } from "components/Transition";

import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./scrollToTop.module.scss";

interface IScrollToTop {
  breakpoint?: string;
}

export const ScrollToTop: FC<IScrollToTop> = ({ breakpoint = "desktop" }) => {
  const [shown, setShown] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const showButton = () => {
      if (document.documentElement.scrollTop > 400) {
        setShown(true);
      } else {
        setShown(false);
      }
    };
    window.addEventListener("scroll", showButton);

    return () => window.removeEventListener("scroll", showButton);
  }, []);

  return (
    <Transition shown={shown}>
      <Button
        onClick={scrollToTop}
        className={classNames(styles.button, styles[breakpoint])}
      >
        <ArrowIcon />
      </Button>
    </Transition>
  );
};

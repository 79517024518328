import { FC, useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import classNames from "classnames";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRef } from "swiper";

import { Accordeon } from "components/Accordeon";
import { Tabs } from "components/Tabs";
import { getNomenclature } from "services/priceApi";
import { toast } from "react-toastify";
import { Spinner2 } from "components/Spinner2";
import { NoImage } from "components/NoImage";

import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import { Related, TRelated } from "../Related";

import styles from "./productModal.module.scss";
import { Characteristics, TCharacteristic } from "./Characteristics";
import { Applicabilty } from "./Applicability";
import { Oem, TCross } from "./Oem";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export type TBrand = {
  id: string;
  name: string;
};

export type TImage = {
  id: string;
  key: string;
  size: string;
  mime: string;
};

export type TNomenclatureFull = {
  id: string;
  article: string;
  brand: TBrand;
  name: string;
  barcode: string;
  images: TImage[];
  characteristicsValue: TCharacteristic[];
  cross: TCross[];
  relatedProducts: TRelated[];
};

interface IProductModal {
  nomenclatureId: string;
  setNomenclatureId: (nomenclatureId: string) => void;
  breakpoint?: string;
}

export const ProductModal: FC<IProductModal> = ({
  nomenclatureId,
  setNomenclatureId,
  breakpoint = "desktop"
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperRef | null>(null);
  const [nomenclature, setNomenclature] = useState<TNomenclatureFull | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!nomenclatureId) return;
    setLoading(true);
    getNomenclature(nomenclatureId)
      .then((res: TNomenclatureFull) => {
        setNomenclature(res);
      })
      .catch((e: any) => toast.error(e.response?.data?.message))
      .finally(() => {
        setLoading(false);
        // setNomenclatureId("");
      });
  }, [nomenclatureId]);

  return (
    <div className={classNames(styles.product, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}
      <div className={styles.top}>

        <div className={styles.caroucelBlock}>
          {breakpoint === "desktop" &&
            <div className={styles.thumbsWrapper}>
              <div ref={navigationPrevRef} className={styles.prevButton} ><ArrowIcon /></div>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={styles.thumbs}
                direction={"vertical"}
              >
                {(nomenclature?.images && nomenclature?.images.length) ? (
                  nomenclature?.images.map((img, i) => (
                    <SwiperSlide className={styles.thumb} key={i}>
                      <img src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${img.key}`} alt="" />
                    </SwiperSlide>

                  ))
                ) : (
                  <NoImage className={styles.noImageThumb} />
                )}
              </Swiper>
              <div ref={navigationNextRef} className={styles.nextButton}><ArrowIcon /></div>
            </div>
          }

          <Swiper
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className={styles.slideWrapper}
            navigation={{
              prevEl: navigationPrevRef.current!,
              nextEl: navigationNextRef.current!,
            }}
          >
            {(nomenclature?.images && nomenclature?.images.length) ? (
              nomenclature?.images.map((img, i) => (
                <SwiperSlide className={styles.slide} key={i}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${img.key}`} alt="" />
                </SwiperSlide>

              ))
            ) : (
              <NoImage className={styles.noImage} />
            )}
          </Swiper>
          {breakpoint !== "desktop" &&
            <div className={styles.thumbsWrapper}>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={"auto"}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={styles.thumbs}
              >
                {(nomenclature?.images && nomenclature?.images.length) ? (
                  nomenclature?.images.map((img, i) => (
                    <SwiperSlide className={styles.thumb} key={i}>
                      <img src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${img.key}`} alt="" />
                    </SwiperSlide>

                  ))
                ) : (
                  <NoImage className={styles.noImageThumb} />
                )}
              </Swiper>
            </div>
          }

        </div>

        <div className={styles.infoBlock}>

          {breakpoint === "desktop" ? <>
            <div className={styles.article}>Артикул: {nomenclature?.article}</div>
            <div className={styles.brand}>Бренд: {nomenclature?.brand?.name}</div>
          </>
            : <div className={styles.articlesBlock}>
              <div className={styles.brand}>Бренд: {nomenclature?.brand?.name}</div>
              <div className={styles.article}>Артикул: {nomenclature?.article}</div>
            </div>
          }
          {breakpoint === "desktop" ? <>
            <div className={styles.name}>{nomenclature?.name}</div>
            <div className={styles.barcode}>
              {nomenclature?.barcode && (
                <Barcode value={nomenclature?.barcode} width={1.7} height={70} fontSize={13} format="EAN13" />
              )}
            </div>
          </> :
            <div className={styles.nameBlock}>
              <div className={styles.name}>{nomenclature?.name}</div>
              {nomenclature?.barcode && (
                <Barcode
                  value={nomenclature?.barcode}
                  width={1.7}
                  height={70}
                  fontSize={13}
                  marginTop={-10}
                  format="EAN13"
                />
              )}
            </div>
          }
        </div>
      </div>
      <div className={styles.bottom}>
        {nomenclature && (
          <>
            {breakpoint === "desktop" ? (
              <Tabs
                tabs={[
                  "Техническая информация",
                  "Применяемость",
                  "ОЕМ Номера",
                  "Сопутствующие товары"
                ]}
              >
                <div className={styles.tabContent}>
                  <Characteristics
                    characteristics={nomenclature?.characteristicsValue}
                  />
                </div>
                <div className={styles.tabContent}>
                  <Applicabilty />
                </div>
                <div className={styles.tabContent}>
                  <Oem cross={nomenclature?.cross} />
                </div>
                <div className={styles.tabContent}>
                  <Related products={nomenclature?.relatedProducts} />
                </div>
              </Tabs>
            ) : (
              <Accordeon
                titles={[
                  "Техническая информация",
                  "Применяемость",
                  "ОЕМ Номера",
                  "Сопутствующие товары"
                ]}
              >
                <Characteristics
                  characteristics={nomenclature?.characteristicsValue}
                />
                <Applicabilty />
                <Oem cross={nomenclature?.cross} />
                <Related products={nomenclature?.relatedProducts} />
              </Accordeon>
            )}
          </>
        )}
      </div>
    </div>
  );
};

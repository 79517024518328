import { FC, ReactNode, useState, useEffect } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Header } from "layout/Header";
import { BreadCrumbs, TBreadCrumbsItem } from "layout/BreadCrumbs";
import { Sidebar } from "layout/Sidebar";
import { Footer } from "layout/Footer";
import { useBreakpoint } from "hooks/useBreakpoint";
import { GoBack } from "layout/GoBack";
import { HeaderWithoutAuth } from "layout/HeaderWithoutAuth";
import brands from "assets/img/png/brands.png";

import styles from "./auth.module.scss";

export interface IAuth {
  children: ReactNode;
  breadCrumbs?: TBreadCrumbsItem[];
}

export const AuthLayout: FC<IAuth> = ({ children, breadCrumbs }) => {
  const { breakpoint, isDesktop } = useBreakpoint();

  return (
    <div>
      <HeaderWithoutAuth />
      <div className={classNames(styles.wrapper, styles[breakpoint])}>
        <main>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import { Button } from "components/Button";

import styles from "./registerResult.module.scss";

interface IRegister {
  setShownModalResult: (shownModalResult: boolean) => void;
  breakpoint?: string;
}

export const RegisterResult: FC<IRegister> = ({ setShownModalResult, breakpoint = "desktop" }) => {
  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.container}>
        <div className={styles.title}>Спасибо за регистрацию</div>
        <div className={styles.text}>
          На указанный E-mail отправлена ссылка для подтверждения аккаунта
        </div>
      </div>
      <Button
        onClick={() => setShownModalResult(false)}
        className={styles.button}
        breakpoint={breakpoint}
      >
        ОК
      </Button>
    </div>
  );
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./card.module.scss";

interface ICard {
  children: ReactNode;
  className?: string;
  breakpoint?: string;
}

export const Card: FC<ICard> = ({
  children,
  className,
  breakpoint = "desktop",
}) => {
  return <div className={
    classNames(styles.card,
      className,
      styles[breakpoint])
  }>
    {children}
  </div>;
};
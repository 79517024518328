import { FC } from "react";
import classNames from "classnames";
import { Button } from "components/Button";

import styles from "./removeConfirm.module.scss";

interface IRemoveConfirm {
    breakpoint?: string;
    onConfirm: () => void;
}

export const RemoveConfirmContentModal: FC<IRemoveConfirm> = ({ breakpoint = "desktop", onConfirm }) => {

    return <div className={classNames(styles.wrapper, styles[breakpoint])}>
        <div className={styles.title}>Удалить товары</div>
        <div className={styles.subTitle}>
            Вы точно хотите удалить выбранные товары?<br />
            Отменить данное действие будет невозможно
        </div>
        <Button
            className={styles.enter}
            breakpoint={breakpoint}
            onClick={() => onConfirm()}
        >
            Удалить
        </Button>
    </div>
}
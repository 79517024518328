import { FC, forwardRef, SetStateAction, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask, { Props as InputMaskProps, ReactInputMask } from "react-input-mask";
import moment, { Moment, DurationInputArg2 } from "moment";
import classNames from "classnames";
import { useBreakpoint } from "hooks/useBreakpoint";

import { Input } from "components/Input";
import { dateFormat, dateMonthFormat } from "utils/format";
import { ucFirst } from "utils/ucFirst";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./dateRange.module.scss";

const PERIODS = [
  { id: "today", text: "Сегодня" },
  { id: "esterday", text: "Вчера" },
  { id: "week", text: "Неделя" },
  { id: "month", text: "Месяц" },
  { id: "quarter", text: "Квартал" }
];

interface IDataRange {
  setDtStart?: any;
  setDtEnd?: any;
  dtStart?: string;
  dtEnd?: string;
  className?: string;
}

export const DateRange: FC<IDataRange> = ({
  setDtStart,
  setDtEnd,
  dtStart,
  dtEnd,
  className }) => {
  const { breakpoint } = useBreakpoint();
  const [value, setValue] = useState((dtStart && dtEnd) ? `${dtStart} - ${dtEnd}` : "");
  const calendar = useRef<HTMLDivElement>(null);
  const [dateStart, setDateStart] = useState<Date | null>(dtStart ? moment(dtStart, "DD.MM.YYYY").toDate() : null);
  const [dateEnd, setDateEnd] = useState<Date | null>(dtEnd ? moment(dtEnd, "DD.MM.YYYY").toDate() : null);
  const [open, setOpen] = useState(false);
  const [mask, setMask] = useState("");
  const [period, setPeriod] = useState("");

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent): void => {
      if (
        !calendar?.current ||
        !(calendar?.current as Node).contains(target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    setDateStart(dtStart ? moment(dtStart, "DD.MM.YYYY").toDate() : null);
    setDateEnd(dtEnd ? moment(dtEnd, "DD.MM.YYYY").toDate() : null);
    if (!dtStart && !dtEnd) {
      setValue("");
    }
  }, [dtStart, dtEnd]);

  const changeDateStart = (date: Date | null) => {
    setDateStart(date);
    if (setDtStart) {
      setDtStart(date ? moment(date).format("DD.MM.YYYY") : "");
    }
  };

  const changeDateEnd = (date: Date | null) => {
    setDateEnd(date);
    if (setDtEnd) {
      setDtEnd(date ? moment(date).format("DD.MM.YYYY") : "");
    }
  };

  const changeValue = (val: string) => {
    setValue(val);
    setPeriod("");
    if (val.length === 23) {
      const [start, end] = val.split(" - ");
      const startMoment = moment(start, "DD.MM.YYYY");
      const endMoment = moment(end, "DD.MM.YYYY");
      if (startMoment.isValid() && endMoment.isValid()) {
        changeDateStart(startMoment.toDate());
        changeDateEnd(endMoment.toDate());
      }
    }
  };

  const selectPeriod = (period: string) => {
    setPeriod(period);
    setMask("");

    switch (period) {
      case "today":
        setValue("Сегодня");
        changeDateStart(new Date());
        changeDateEnd(new Date());
        break;
      case "esterday":
        setValue("Вчера");
        changeDateStart(moment().subtract(1, "days").toDate());
        changeDateEnd(moment().subtract(1, "days").toDate());
        break;
      case "week":
        const start = moment().startOf("week").add(1, "days");
        setValue(`${start.format("DD.MM.YYYY")} - ${moment().format("DD.MM.YYYY")}`);
        changeDateStart(start.toDate());
        changeDateEnd(new Date());
        break;
      case "month":
        setValue(`${ucFirst(dateMonthFormat(new Date()))}`);
        changeDateStart(moment().startOf("month").toDate());
        changeDateEnd(new Date());
        break;
      case "quarter":
        setValue(`${moment().quarter()} квартал ${moment().format("YYYY")} г`);
        changeDateStart(moment().startOf("quarter").toDate());
        changeDateEnd(new Date());
        break;
      default:
        break;
    }

    setOpen(false);
  };

  const getDateText = (date: Date) => {
    if (moment(date).isSame(moment().subtract(1, "days"), "day")) {
      return "Вчера";
    };
    if (moment(date).isSame(moment(), "day")) {
      return "Сегодня";
    };
    return false;
  };

  const getResultDate = (
    date: Date,
    dir: string,
    period: string,
    type?: string
  ): Date => {
    const dateNew = dir === "left" ?
      moment(date).subtract(1, period as DurationInputArg2) :
      moment(date).add(1, period as DurationInputArg2);

    if (type === "start") {
      return dateNew.startOf(period as DurationInputArg2).toDate();
    }
    if (type === "end") {
      return dateNew.endOf(period as DurationInputArg2).toDate();
    }

    return dateNew.toDate();
  };

  const changePeriod = (dir: string) => {

    switch (period) {
      case "today":

        changeDateStart(dateStart ? getResultDate(dateStart, dir, "days") : null);

        changeDateEnd(dateEnd ? getResultDate(dateEnd, dir, period, "days") : null);

        if (dateStart) {
          setValue(
            getDateText(getResultDate(dateStart, dir, "days"))
            || dateFormat(getResultDate(dateStart, dir, "days")));
        }

        break;
      case "esterday":

        changeDateStart(dateStart ? getResultDate(dateStart, dir, "days") : null);

        changeDateEnd(dateEnd ? getResultDate(dateEnd, dir, "days") : null);

        if (dateStart) {
          setValue(getDateText(getResultDate(dateStart, dir, "days"))
            || dateFormat(getResultDate(dateStart, dir, "days")));
        }

        break;
      case "week":

        changeDateStart(dateStart ? getResultDate(dateStart, dir, "isoWeek", "start") : null);

        changeDateEnd(dateEnd ? getResultDate(dateEnd, dir, "isoWeek", "end") : null);

        if (dateStart && dateEnd) {
          setValue(
            `${dateFormat(getResultDate(dateStart, dir, "isoWeek", "start"))
            } - ${dateFormat(getResultDate(dateEnd, dir, "isoWeek", "end"))}`
          );
        }

        break;

      case "month":

        changeDateStart(dateStart ? getResultDate(dateStart, dir, period, "start") : null);

        if (dateStart) {
          setValue(
            `${ucFirst(dateMonthFormat(getResultDate(dateStart, dir, period, "start")))}`
          );
        };

        changeDateEnd(dateEnd ? getResultDate(dateEnd, dir, period, "end") : null);

        break;
      case "quarter":

        changeDateStart(dateStart ? getResultDate(dateStart, dir, period, "start") : null);

        if (dateStart) {
          setValue(
            `${moment(getResultDate(dateStart, dir, period, "start")).quarter()
            } квартал ${moment(getResultDate(dateStart, dir, period, "start")).format("YYYY")} г`
          );
        }

        changeDateEnd(dateEnd ? getResultDate(dateEnd, dir, period, "end") : null);

        break;
      default:
        break;
    }

  };

  return <div className={classNames(
    styles.dateRange,
    styles[breakpoint],
    className)
  }>
    <div ref={calendar}>
      <div className={styles.inputWrapper}>
        <button
          className={styles.prevButton}
          onClick={() => changePeriod("left")}
          disabled={!period}
        >
          <ArrowIcon />
        </button>
        <Input
          id="dateStart"
          label="Период"
          onChange={e => changeValue(e.target.value)}
          onKeyDown={() => {
            setMask("99.99.9999 - 99.99.9999");
          }}
          value={value}
          mask={mask}
          className={styles.input}
          onClick={() => setOpen(true)}
        />
        <button
          className={styles.nextButton}
          onClick={() => changePeriod("right")}
          disabled={!period}
        >
          <ArrowIcon />
        </button>
        <div className={styles.icon} onClick={() => setOpen(true)} />
      </div>

      {open && <>
        <div className={styles.calendar}>
          <div className={styles.periods}>

            {PERIODS.map(val => <button
              key={val.id}
              type="button"
              className={classNames({ [styles.selected]: period === val.id })}
              onClick={() => selectPeriod(val.id)}>
              {val.text}
            </button>)}

          </div >
          <DatePicker
            selectsRange={true}
            startDate={dateStart || new Date()}
            endDate={dateEnd || undefined}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            inline
            calendarClassName={styles.calendarBlock}
            onChange={(dates) => {
              const [start, end] = dates;
              changeDateStart(start);
              changeDateEnd(end);
              setValue(`${dateFormat(start)} - ${dateFormat(end)} `);
              if (start && end) {
                setOpen(false);
              }
              setPeriod("");
            }}
          />
        </div>
      </>
      }
    </div>
  </div >;
};
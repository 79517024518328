import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useBreakpoint } from "hooks/useBreakpoint";

import { ReactComponent as IconCart } from "assets/img/svg/cart.svg";
import { useCart } from "context/CartContext";
import { priceFormat } from "utils/format";
import { count, summ } from "utils/cart";

import styles from "./miniCart.module.scss";

export const MiniCart: FC = () => {
  const { breakpoint } = useBreakpoint();
  const { cart } = useCart();

  return (
    <Link to="/cart" className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.icon}>
        <IconCart />
      </div>
      <div>
        <div className={styles.summ}>{priceFormat(summ(cart))} р.</div>
        <div className={styles.count}>товаров: {count(cart)}</div>
      </div>
    </Link>
  );
};

import { FC, useState, useEffect } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "hooks/redux";


import { useBreakpoint } from "hooks/useBreakpoint";
import { BottomNavbar } from "components/BottomNavbar";
import { Button } from "components/Button";
import { useModal } from "hooks/useModal";
import { AddToCartModal } from "components/AddToCartModal";
import { useSearch } from "context/SearchContext";
import { useCart } from "context/CartContext";
import { useUser } from "context/UserContext";
import { sortResultAnalog } from "utils/sort";
import { ProductModal } from "components/ProductModal";
import { useProductModal } from "hooks/useProductModal";
import { Spinner } from "components/Spinner";
import { TProduct } from "components/ProductInfo";
import { RightSidebar } from "layout/RightSidebar";
import { MainBlock } from "layout/MainBlock";
import { Body } from "layout/Body";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { Select } from "components/Select";
import { articleNormalize } from "utils/articleNormalizer";
import { searchSlice } from "store/reducers/SearchSlice";
import { fetchSearch } from "store/reducers/ActionCreators";
import {
  TBrand,
  TBrandSearch,
  TBrandSearchArray,
  TProductSearch, TProductSearchArray, TProductSearchValue
} from "services/priceApi";

import styles from "./search.module.scss";
import { TopProduct } from "./TopProduct";
import { Product } from "./Product";
import { Filter, TFilter } from "./Filter";
import { TOffer } from "./Offer";
import { SelectBrand } from "./SelectBrand";


export type TAnalogues = {
  itemProduct: TProductSearchArray;
  itemBrand: TBrandSearchArray;
  inStock: boolean;
};

const SORT_VALUES = [
  { label: "Лучший выбор", value: "default" },
  { label: "Сначала дешевые", value: "price" },
  { label: "Сначала быстрые", value: "deliveryTime" },
  { label: "Сначала от надежных", value: "probability" }
];

export const SearchPage: FC = () => {
  const [shownFilter, setShownFilter] = useState(false);
  const [sort, setSort] = useState<string>("default");
  const { breakpoint, isDesktop, isMobile, isTablet } = useBreakpoint();
  const [shown, setShown, Modal] = useModal();
  const { ProdModal } = useProductModal();
  const {
    nomenclatureId,
    setNomenclatureId
  } = useSearch();

  const [filteredResult, setFilteredResult] = useState<TBrandSearch>({});
  const [filter, setFilter] = useState<TFilter>({ inStock: false, brands: [] });
  const [product, setProduct] = useState<TProductSearchValue | null>(null);
  const { added, setAdded } = useCart();
  const { user } = useUser();

  const { searchLoading, result, article, brand, suggestionBrands } = useAppSelector(state => state.searchReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {

    if (!result?.data) return;
    setFilteredResult(result?.data);

    if (result.data.hasOwnProperty(brand) &&
      result.data[brand].hasOwnProperty(articleNormalize(article))) {
      setProduct(result.data[brand][articleNormalize(article)]);
    }

  }, [result]);

  const selectBrand = (item: TBrand) => {
    dispatch(searchSlice.actions.setArticle(item.partNumber));
    dispatch(searchSlice.actions.setBrand(item.brand));
    dispatch(fetchSearch({ article: item.partNumber, brand: item.brand, userId: user.id }));
    dispatch(searchSlice.actions.setSuggestionBrands([]));
  };

  useEffect(() => {
    if (!added) return;
    setShown(true);
  }, [added]);

  useEffect(() => {

    if (!shown) {
      setAdded(null);
    };
  }, [shown]);

  const onFilter = (filterData: TFilter) => {
    if (!result?.data) return;

    setFilter(filterData);

    const filtered = filterData.brands.reduce(
      (a: TBrandSearch, key: string) =>
        ((a[key] = result?.data[key], a)), {}
    );

    if (!filterData.brands.length) {
      setFilteredResult(result?.data);
    } else {
      setFilteredResult(filtered);
    }
  };

  const setShownMore = (
    article: string,
    brand: string,
    shownMore: number
  ) => {
    dispatch(searchSlice.actions.setShownMore({ brand, article, shownMore }));
  };

  const AddressResults = () =>
    <div>Результаты по магазину:
      {" "}{user?.branch[0]
        ? "Москва, ул. Нижние поля, 31"
        : "Воронеж, ул. Остужева, 45"}
    </div>;

  const BestOffers = () => {

    const output: any = {
      byPrice: null,
      byDelivery: null,
      byAnalogue: null
    };

    Object.entries<TProductSearch>(filteredResult).map(
      (itemBrand: TBrandSearchArray) => {
        return Object.entries<TProductSearchValue>(itemBrand[1]).map(
          (itemProduct: TProductSearchArray) => {
            for (const offer of itemProduct[1].offer) {
              if (brand === itemBrand[0] && articleNormalize(article) === itemProduct[0]) {
                if (
                  !output.byPrice
                  || offer.price < output.byPrice?.price
                ) {
                  output.byPrice = { ...offer, ...itemProduct[1] };
                  output.byPrice.brand = itemBrand[0];
                }
                if (
                  !output.byDelivery
                  || offer.deliveryTime < output.byDelivery?.deliveryTime
                ) {
                  output.byDelivery = { ...offer, ...itemProduct[1] };
                  output.byDelivery.brand = itemBrand[0];
                }
              } else {
                if (
                  !output.byAnalogue
                  || offer.price < output.byAnalogue?.price
                ) {
                  output.byAnalogue = { ...offer, ...itemProduct[1] };
                  output.byAnalogue.brand = itemBrand[0];
                }

              }
            }
            return false;
          }
        );
      }
    );

    return <>
      {Object.entries(output).map(([type, item]: any) =>
        <TopProduct
          type={type}
          key={type}
          product={item}
          breakpoint={breakpoint}
        />)}
    </>;
  };

  const getAnalogues = () => {
    const output: TAnalogues[] = [];

    Object.entries<TProductSearch>(filteredResult).map(
      (itemBrand: TBrandSearchArray) => {
        return Object.entries<TProductSearchValue>(itemBrand[1]).map(
          (itemProduct: TProductSearchArray) => {
            if (itemProduct[0] !== articleNormalize(article) || itemBrand[0] !== brand) {
              const inStock =
                itemProduct[1].offer &&
                itemProduct[1].offer.findIndex(
                  (offer: TOffer) => offer.inStock === true
                );
              output.push({
                itemProduct,
                itemBrand,
                inStock: inStock === -1 ? false : true
              });
            }
            return false;
          }
        );
      }
    );

    return sortResultAnalog({ sort, items: output });
  };

  const SearchBlock = () => (
    <>
      <div className={styles.topProducts}>
        <BestOffers />
      </div>
      <div className={styles.searchResult}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>Запрашиваемый код</div>
          <Select
            options={SORT_VALUES}
            value={SORT_VALUES.find(val => val.value === sort) || { label: "Лучший выбор", value: "default" }}
            className={styles.sort}
            onSelect={(val) => setSort(val.value)}
            breakpoint={breakpoint}
          />
        </div>
        <div className={styles.resultList}>
          {result !== null &&
            <> {(product && product?.offer.length) ? (
              <Product
                product={product}
                article={article}
                brand={brand}
                filter={filter}
                sort={sort}
                shownMore={product.shownMore}
                setShownMore={setShownMore}
                breakpoint={breakpoint}
              />
            ) : (
              <div className={styles.notFind}>
                По запросу{" "}
                <>
                  {article} {brand} ничего не найдено. Измените условия поиска.
                </>
              </div>
            )}
            </>
          }
        </div>
      </div>

      <div className={styles.searchResult}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>Аналоги</div>
        </div>
        <div className={styles.resultList}>
          {getAnalogues().map(({ itemBrand, itemProduct }: TAnalogues) => (
            <Product
              key={`${itemBrand[0]}_${itemProduct[0]}`}
              article={itemProduct[0]}
              brand={itemBrand[0]}
              product={itemProduct[1] as TProduct}
              filter={filter}
              sort={sort}
              shownMore={(itemProduct[1] as TProduct).shownMore}
              setShownMore={setShownMore}
              breakpoint={breakpoint}
            />
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <BreadCrumbs data={[{ title: "Поиск детали по коду" }]} />
      <Helmet>
        <title>Поиск детали по коду</title>
      </Helmet>
      <div className={styles.headWrapper}>
        <div className={styles.head}>
          {result ? (
            <>
              {isDesktop ? (
                <>
                  <div className={styles.title}>
                    <span className={styles.article}>
                      {article} {brand}
                    </span>{" "}
                    {product?.name}
                  </div>
                  <div className={styles.info}>
                    <AddressResults />
                    <div>
                      Информация об{" "}
                      <a
                        href="https://lider-truck.ru/ob-oplate/"
                        rel="noreferrer"
                      >
                        оплате
                      </a>{" "}
                      и{" "}
                      <a
                        href="https://lider-truck.ru/o-dostavke/"
                        rel="noreferrer"
                      >
                        доставке
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={classNames(styles.titleBlock, { [styles.withoutFilter]: !brand })}>
                    <div className={styles.title}>
                      <span className={styles.article}>
                        {article} {brand}
                      </span>{" "}
                      {product?.name}
                    </div>
                    <div className={styles.info}>
                      <AddressResults />
                    </div>
                  </div>
                  {!isTablet && (
                    <>
                      {brand &&
                        <div className={styles.filter}>
                          <button onClick={() => setShownFilter(true)} />
                        </div>
                      }
                    </>
                  )}

                  <BottomNavbar
                    title="Фильтр"
                    shown={shownFilter}
                    setShown={setShownFilter}
                  >
                    <Filter data={result?.data} onFilter={onFilter} />
                    <div className={styles.apply}>
                      <Button
                        className={styles.button}
                        onClick={() => setShownFilter(false)}
                        breakpoint={breakpoint}
                      >
                        Применить
                      </Button>
                    </div>
                  </BottomNavbar>
                </>
              )}
            </>
          ) : (
            <div className={styles.title}>Поиск детали по коду</div>
          )}
        </div>
      </div>
      <Body className={styles.body}>
        {searchLoading && <Spinner />}
        <MainBlock>
          {brand
            ? <SearchBlock />
            : <SelectBrand
              brands={suggestionBrands}
              selectBrand={selectBrand}
              breakpoint={breakpoint} />
          }
        </MainBlock>
        {!isMobile && (
          <RightSidebar>
            {result && <Filter data={result?.data} onFilter={onFilter} />}
          </RightSidebar>
        )}
        <Modal className={styles.addToCartModal}>
          <AddToCartModal
            product={added}
            setShown={setShown}
            breakpoint={breakpoint}
          />
        </Modal>
        <ProdModal className={styles.prodModal}>
          <ProductModal
            nomenclatureId={nomenclatureId}
            setNomenclatureId={setNomenclatureId}
            breakpoint={breakpoint}
          />
        </ProdModal>
      </Body>
    </div>
  );
};

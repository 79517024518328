import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

import { RoutesRender } from "routes/Routes";
import { SearchProvider } from "context/SearchContext";
import { CartProvider } from "context/CartContext";
import { UserProvider } from "context/UserContext";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./assets/scss/base.scss";

const App: FC = () => (
  <BrowserRouter>
    <UserProvider>
      <CartProvider>
        <SearchProvider>
          <RoutesRender />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </SearchProvider>
      </CartProvider>
    </UserProvider>
  </BrowserRouter>
);
export default App;

import { FC, ReactNode } from "react";

import { Manager } from "pages/Search/Manager";

import styles from "./rightSidebar.module.scss";

interface IRightSidebar {
  children?: ReactNode;
}

export const RightSidebar: FC<IRightSidebar> = ({ children }) => {
  return (
    <div className={styles.rightSidebar}>
      {children}
      <Manager />
    </div>
  );
};

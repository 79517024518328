import { FC, useState } from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";
import classNames from "classnames";

import { FormGroup } from "components/FormGroup";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { useUser } from "context/UserContext";
import { TUpdateProfile, updateProfile } from "services/userApi";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./personalInfo.module.scss";

const PersonalInfoSchema = object().shape({
  firstName: string().min(2).required(),
  phone: string().min(2).required(),
  email: string().email().min(2).required(),
});

export const PersonalInfo: FC = () => {
  const { breakpoint } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();

  const updateProfileHandler = (
    values: TUpdateProfile,
    resetForm: () => void
  ) => {
    setLoading(true);
    updateProfile(values)
      .then((res) => {
        setUser(res);
        resetForm();
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles[breakpoint])}>
      <div className={styles.title}>Персональная информация</div>
      <Formik
        initialValues={{
          lastName: user.lastName || "",
          firstName: user.firstName || "",
          middleName: user.middleName || "",
          phone: user.phone || "",
          email: user.email || "",
        }}
        validationSchema={PersonalInfoSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateProfileHandler(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup
              title="Личные данные"
              subtitle="Укажите своё имя, чтобы мы знали как к вам обращаться"
              rightText={<>Тип: <span className={styles.type}>
                {user.type === 1 ? "Физическое лицо" : "Юридическое лицо"}</span>
              </>}
            >
              <Input
                id="lastName"
                label="Фамилия"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                className={styles.input}
                error={
                  errors.lastName && touched.lastName
                    ? (errors.lastName as string)
                    : null
                }
              />
              <Input
                id="firstName"
                label="Имя"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                className={styles.input}
                error={
                  errors.firstName && touched.firstName
                    ? (errors.firstName as string)
                    : null
                }
              />
              <Input
                id="middleName"
                label="Отчество"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.middleName}
                className={styles.input}
                error={
                  errors.middleName && touched.middleName
                    ? (errors.middleName as string)
                    : null
                }
              />
            </FormGroup>
            <FormGroup
              title="Телефон"
              subtitle="Укажите телефон, чтобы мы могли связаться с вами"
            >
              <Input
                id="phone"
                label="Телефон"
                type="tel"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={styles.input}
                error={
                  errors.phone && touched.phone
                    ? (errors.phone as string)
                    : null
                }
                mask="+7(999) 999-99-99"
              />
            </FormGroup>
            <FormGroup
              title="E-mail"
              subtitle="На почту приходят уведомления, счета, закрывающие документы"
            >
              <Input
                id="email"
                type="email"
                label="E-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={styles.input}
                error={
                  errors.email && touched.email
                    ? (errors.email as string)
                    : null
                }
              />
            </FormGroup>
            <div>
              <Button
                type="submit"
                className={styles.button}
                breakpoint={breakpoint}
              >
                Сохранить
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

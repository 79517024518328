import { axiosInstance } from "./api";

export type ICartAdd = {
  article: string;
  brand: string;
  name: string;
  exist: string;
  price: number;
  providerId: string;
  priceListId: string;
  count: number;
};

export const cartAdd = async (props: ICartAdd) => {
  const res = await axiosInstance.post("/cart.add", props);
  return res.data;
};

export type TCartUpdate = { id: string; count: number };

export const cartUpdate = async (props: TCartUpdate) => {
  const res = await axiosInstance.post("/cart.update", props);
  return res.data;
};

export type TCartRemove = { ids: string[] };

export const cartRemove = async (props: TCartRemove) => {
  const res = await axiosInstance.post("/cart.remove", props);
  return res.data;
};

export const cartGet = async () => {
  const res = await axiosInstance.get("/cart.get");
  return res.data;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

import { ISearchResult, TProductSearchValue } from "services/priceApi";
import { articleNormalize } from "utils/articleNormalizer";
import { TOffer } from "pages/Search/Offer";


interface IShownMore {
  article: string;
  brand: string;
  shownMore: number;
}

interface ISuggestionBrand {
  brand: string;
  name: string;
  partNumber: string;
}

interface SearchState {
  searchLoading: boolean;
  article: string;
  brand: string;
  result: ISearchResult | null;
  suggestionBrands: ISuggestionBrand[];
  product: TProductSearchValue | null;
}

const initialState: SearchState = {
  searchLoading: false,
  article: "",
  brand: "",
  result: null,
  suggestionBrands: [],
  product: null
};

const searchMap = (state: SearchState, action: PayloadAction<ISearchResult>): ISearchResult => {

  const stateCloned = cloneDeep(state);

  const output = stateCloned.result?.data ? stateCloned.result : action.payload;

  for (const [brand1, brandData] of Object.entries(action.payload?.data)) {

    if (!brandData) continue;

    for (const [article1, articleData] of Object.entries(brandData)) {

      if (typeof output.data[brand1] === "undefined") {
        output.data[brand1] = brandData;
      }

      if (typeof output.data[brand1][article1] === "undefined") {
        output.data[brand1][article1] = articleData;
      }

      const product = output.data[brand1][article1];

      if (product) {

        if (!product.offer) continue;

        for (const offer of action.payload?.data[brand1][article1].offer) {

          if (product.offer.find((v: TOffer) => v.id === offer.id)) continue;

          output.data[brand1][article1].offer = [
            ...product.offer,
            offer
          ];
        }

      } else {
        output.data[brand1][article1] = action.payload?.data[brand1][article1];
      }

    }
  }

  return output;

};


export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchLoading(state, action: PayloadAction<boolean>) {
      state.searchLoading = action.payload;
    },
    cleanResult(state) {
      state.result = null;
    },
    set(state, action: PayloadAction<ISearchResult>) {
      if (!state.brand) {
        state.brand = action.payload.info?.brand;
      }

      if (!state.article) {
        state.article = action.payload.info?.partNumber;
      }

      if (action.payload.data.hasOwnProperty(state.brand) &&
        action.payload.data[state.brand].hasOwnProperty(articleNormalize(state.article))) {

        if (!state.product) {
          state.product = action.payload.data[state.brand][articleNormalize(state.article)];
        }

        state.result = searchMap(state, action);
      }

    },
    setSuggestionBrands(state, action: PayloadAction<ISuggestionBrand[]>) {
      state.suggestionBrands = action.payload;
    },
    setProduct(state, action: PayloadAction<TProductSearchValue>) {
      state.product = action.payload;
    },
    setArticle(state, action: PayloadAction<string>) {
      state.article = action.payload;
    },
    setBrand(state, action: PayloadAction<string>) {
      state.brand = action.payload;
    },
    setShownMore(state, action: PayloadAction<IShownMore>) {
      const stateCloned = cloneDeep(state);
      if (stateCloned.result) {
        stateCloned.result.data[action.payload.brand][articleNormalize(action.payload.article)]["shownMore"] =
          action.payload.shownMore;
        state.result = stateCloned.result;
      }
    }
  }
});

export const { set } = searchSlice.actions;
export default searchSlice.reducer;
import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Formik } from "formik";
import { object, string } from "yup";

import { Button } from "components/Button";
import { updatePassword } from "services/userApi";
import { Input } from "components/Input";
import { useBreakpoint } from "hooks/useBreakpoint";
import { FormGroup } from "components/FormGroup";

import styles from "./password.module.scss";

export type TPassword = {
  password: string;
  oldPassword: string;
};

const PasswordSchema = object().shape({
  password: string().min(2).required(),
  oldPassword: string().min(2).required(),
});

export const Password: FC = () => {
  const { breakpoint } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  const updatePasswordHandler = (values: TPassword, resetForm: () => void) => {
    setLoading(true);

    updatePassword(values)
      .then((res) => {
        resetForm();
        toast.success("Пароль успешно обновлен");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles[breakpoint])}>
      <div className={styles.title}>Изменить пароль</div>
      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
        }}
        validationSchema={PasswordSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updatePasswordHandler(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup title="" subtitle="Введите старый и новый пароль">
              <Input
                id="oldPassword"
                type="password"
                label="Старый пароль"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
                className={styles.input}
                error={
                  errors.oldPassword && touched.oldPassword
                    ? errors.oldPassword
                    : null
                }
              />
              <Input
                id="password"
                type="password"
                label="Новый пароль"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={styles.input}
                showPass
                error={
                  errors.password && touched.password ? errors.password : null
                }
              />
            </FormGroup>
            <div>
              <Button
                type="submit"
                className={styles.button}
                breakpoint={breakpoint}
              >
                Сохранить
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

import { FC, useState } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useModal } from "hooks/useModal";

import { useUser } from "context/UserContext";
import { ManagerBig } from "components/ManagerBig";
import { cleanPhone } from "utils/cleanPhone";
import { BottomNavbar } from "components/BottomNavbar";

import styles from "./footer.module.scss";


const MENU_ITEMS = [
  { title: "О компании", url: "https://lider-truck.ru/o-kompanii/" },
  { title: "Оплата", url: "https://lider-truck.ru/ob-oplate/" },
  { title: "Доставка", url: "https://lider-truck.ru/o-dostavke/" }
];

export const Footer: FC = () => {
  const { breakpoint, isMobile } = useBreakpoint();
  const { user } = useUser();
  const [, setShownModal, Modal] = useModal();
  const [shown, setShown] = useState(false);
  return (
    <>
      <div className={classNames(styles.wrapper, styles[breakpoint])} id="footer">
        <nav className={styles.nav}>
          <ul>
            {MENU_ITEMS.map((item) => (
              <li className={styles.navItem} key={item.title}>
                <a href={item.url} rel="noreferrer">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          {user.id && (
            <div className={styles.manager}>
              <div>Ваш менеджер: </div>
              <button onClick={() => isMobile ? setShown(true) : setShownModal(true)} className={styles.link}>
                {user.admin?.name}
              </button>
            </div>
          )}
        </nav>
        <div className={styles.phoneBlock}>
          <a
            rel="noreferrer"
            href={`tel:${cleanPhone("+7(999) 916-50-00")}`}
          >
            +7(999) 916-50-00
          </a>
        </div>
      </div>
      <Modal>
        <ManagerBig />
      </Modal>
      <BottomNavbar
        shown={shown}
        setShown={setShown}
      >
        <ManagerBig />
      </BottomNavbar>
    </>
  );
};

import React from "react";

import "./cut.scss";

export const Cut = ({ children }) => {
  return (
    <div className="cut">
      <span>{children}</span>
    </div>
  );
};

import { FC, ReactNode, useEffect, useRef } from "react";
import { useSwipeable, SwipeEventData } from "react-swipeable";

import { Transition } from "components/Transition";

import styles from "./bottomNavbar.module.scss";

interface IBotttomNavbar {
  children: ReactNode;
  shown: boolean;
  setShown: (shown: boolean) => void;
  title?: string;
}


export const BottomNavbar: FC<IBotttomNavbar> = ({
  children,
  title,
  shown,
  setShown,
}) => {

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const body = document.body;
    if (shown === true) {
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
    }
  }, [shown]);

  const handleSwiped = (eventData: SwipeEventData) => {
    if (ref.current) {
      if (eventData.absY > 170) {
        setShown(false);
      } else {
        ref.current.style.bottom = `0px`;
      }
    }
  };

  const handleSwiping = (eventData: SwipeEventData) => {

    if (eventData.dir === "Up") {
      return;
    }

    if (ref.current) {
      ref.current.style.bottom = `-${eventData.absY}px`;
    }

  }

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    onSwiping: handleSwiping,
    onTouchStartOrOnMouseDown: (({ event }) => {

      //event.preventDefault()

    }),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true
  });


  return (
    <>
      <Transition shown={shown}>
        <div className={styles.wrapper} ref={ref} >

          <div {...handlers}>
            {title && <div className={styles.title}  >{title}</div>}

            <div>{children}</div>


          </div>


        </div>
      </Transition>
      <Transition shown={shown}>
        <div className={styles.overlay} onClick={() => setShown(false)} />
      </Transition>
    </>
  );
};

import { axiosInstance } from "./api";

export const getCurrentUser = async () => {
  const res = await axiosInstance.get("/users.getCurrent");
  return res.data;
};

export type TUpdateProfile = {
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  email: string;
};

export const updateProfile = async (props: TUpdateProfile) => {
  const res = await axiosInstance.post("/users.updateProfile", props);
  return res.data;
};

export type TUpdatePassword = {
  password: string;
};

export const updatePassword = async (props: TUpdatePassword) => {
  const res = await axiosInstance.post("/users.updatePassword", props);
  return res.data;
};

export type TAddAddress = {
  address: string;
};

export const addAddress = async (props: TAddAddress) => {
  const res = await axiosInstance.post("/addresses.add", props);
  return res.data;
};

export type TRemoveAddress = {
  id: string;
};

export const removeAddress = async (props: TRemoveAddress) => {
  const res = await axiosInstance.post("/addresses.remove", props);
  return res.data;
};

export type TDefatultAddress = {
  id: string;
};

export const setDefaultAddress = async (props: TDefatultAddress) => {
  const res = await axiosInstance.post("/addresses.setDefault", props);
  return res.data;
};

export type TUpdateRequisites = {
  orgName: string;
  orgNameShort: string;
  orgAddress: string;
  orgInn: string;
  orgKpp: string;
};

export const updateRequisites = async (props: TUpdateRequisites) => {
  const res = await axiosInstance.post("/users.updateRequisites", props);
  return res.data;
};

export type TResetPassword = {
  email: string;
};

export const resetPassword = async (props: TResetPassword) => {
  const res = await axiosInstance.post("/users.passwordRecovery", props);
  return res.data;
};

export type TNewPassword = {
  code: string;
  password: string;
};

export const newPassword = async (props: TNewPassword) => {
  const res = await axiosInstance.post("/users.newPassword", props);
  return res.data;
};

export const cut = (string, length1 = 30, length2 = 20) => {
  if (typeof string === "undefined") return;
  if (string.length > length1 + length2) {
    return `${string.substring(length1, 0)}...${string.substring(
      string.length - length2
    )}`;
  }
  return string;
};

export const cutEmail = (string) => {
  if (typeof string === "undefined") {
    return null;
  }
  const tmp = string.split("@");
  if (tmp.length < 2) return string;
  const email =
    tmp[0].length > 12
      ? `${tmp[0].substring(4, 0)}...${tmp[0].substring(tmp[0].length - 5)}`
      : tmp[0];

  const domain =
    tmp[1].length > 12
      ? `${tmp[1].substring(2, 0)}...${tmp[1].substring(tmp[1].length - 4)}`
      : tmp[1];

  return `${email}@${domain}`;
};

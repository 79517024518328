import moment from "moment";

export const dateFormat = (date) => {
  if (!date) return "";
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(date).toLocaleDateString("ru-RU", options);
};

export const dateMonthFormat = (date) => {
  if (!date) return "";
  const options = { month: "long" };
  if (date instanceof Date) {
    return date.toLocaleDateString("ru-RU", options);
  }
  return new Date(date).toLocaleDateString("ru-RU", options);
};

export const dateFormatU = (date) => {
  if (!date) return "";
  return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
  // const dateObject = new Date(date);
  // let day = dateObject.getDate();
  // if (String(day).length < 2) {
  //   day = `0${day}`;
  // }
  // let month = dateObject.getMonth() + 1;
  // if (String(month).length < 2) {
  //   month = `0${month}`;
  // }
  // const year = dateObject.getFullYear();
  // return `${year}-${month}-${day}`;
};

export const dateTimeFormat = (date) => {
  if (!date) return "";
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  return new Date(date).toLocaleDateString("ru-RU", options);
};

export const dateFormatTimestmp = (timestamp) => {
  if (!timestamp) return "";
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(timestamp * 1000).toLocaleDateString("ru-RU", options);
};

export const priceFormat = (price) => {
  if (!price) return 0;
  return parseFloat(price).toLocaleString("ru-RU");
};

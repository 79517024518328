import { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./buttonSmall.module.scss";

interface Ibutton {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  breakpoint?: string;
}

export const ButtonSmall: FC<Ibutton> = ({
  children,
  onClick,
  className,
  breakpoint = "desktop",
}) => {
  return (
    <button
      className={classNames(styles.button, className, styles[breakpoint])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./formGroup.module.scss";

interface IFormGroup {
  children: ReactNode;
  title: string;
  subtitle?: string;
  rightText?: ReactNode
}

export const FormGroup: FC<IFormGroup> = ({ children, title, subtitle, rightText = null }) => {
  const { breakpoint, isMobile } = useBreakpoint();
  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      {rightText ? <div className={styles.withRightText}>
        <div className={styles.title}>{title}</div>
        <div className={styles.rightText}>{rightText}</div>
      </div>
        :
        <div className={styles.title}>{title}</div>
      }
      <div className={styles.subTitle}>{subtitle}</div>
      <div className={styles.formBlock}>{children}</div>
    </div>
  );
};

import { FC } from "react";
import { NavLink } from "react-router-dom";

import { useBreakpoint } from "hooks/useBreakpoint";
import { GoBack } from "layout/GoBack";

import styles from "./breadCrumbs.module.scss";

export type TBreadCrumbsItem = {
  title: string;
  link?: string;
};

export interface IBreadCrumbs {
  data?: TBreadCrumbsItem[];
}

export const BreadCrumbs: FC<IBreadCrumbs> = ({ data }) => {
  const { breakpoint, isDesktop } = useBreakpoint();

  if (!isDesktop) {
    return <GoBack breakpoint={breakpoint}/>;
  }

  return (
    <nav className={styles.breadcrumbs}>
      <ul>
        <li>
          <NavLink className={styles.link} to="/">
            Главная
          </NavLink>
        </li>
        {data?.map((item) => (
          <li key={item.title}>
            {item.link ? (
              <NavLink to={item.link} className={styles.link}>
                {item.title}
              </NavLink>
            ) : (
              <span>{item.title}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

import { ReactNode, ReactElement } from "react";

import { RModal } from "components/RModal";
import { useSearch } from "context/SearchContext";

interface Imodal {
  children: ReactNode;
  className?: string;
}

export const useProductModal: () => {
  ProdModal: ({ children, className }: Imodal) => ReactElement<typeof RModal>;
} = () => {
  const { shownModal, setShownModal } = useSearch();

  const ProdModal = ({ children, className }: Imodal) => (
    <RModal shown={shownModal} setShown={setShownModal} className={className}>
      {children}
    </RModal>
  );

  return { ProdModal };
};

import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Checkbox } from "components/Checkbox";
import { TStatus } from "pages/OrderOne";
import { Input } from "components/Input";
import { Button } from "components/Button";

import styles from "./selectStatus.module.scss";




// export type TOption = {
//   label: string;
//   value: string;
// };

// export type TStatus = TOption & {
//   color: string;
// }

interface ISelect {
  options: TStatus[];
  value: string[];
  className?: string;
  onSelect?: (selected: string[] | ((prev: string[]) => string[])) => void;
  breakpoint?: string;
}

export const SelectStatus: FC<ISelect> = ({
  options,
  value,
  className,
  onSelect,
  breakpoint = "desktop"
}) => {
  const dropdownEl = useRef(null);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent): void => {
      if (
        !dropdownEl?.current ||
        !(dropdownEl?.current as Node).contains(target as Node)
      ) {
        setOpened(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const selectHandler = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (onSelect) {
      onSelect((prev: string[]) => {
        if (id === "all") {
          if (e.target.checked) {
            return options.map((v: TStatus) => v.id);
          }
          return [];
        }
        if (!e.target.checked) {
          prev.splice(
            prev.findIndex((v: string) => v === id),
            1
          );
          return [...prev];
        }
        if (!prev.find((v: string) => v === id)) {
          return [...prev, id];
        }
        return [...prev];
      });
    };
  };

  const renderStatusesText = () => {
    if (!options.length) {
      return "";
    }
    return value.map(id => {
      const option = options.find(option => option.id === id);
      return option ? option.nameForCustomer : "";
    }).join("; ");
  };

  return (
    <div
      className={classNames(styles.select,
        styles[breakpoint],
        className,
        { [styles.opened]: opened })
      }
      ref={dropdownEl}

    >
      <Input
        id="selectStatus"
        label="Статус"
        value={renderStatusesText()}
        onChange={() => { }}
        onClick={() => setOpened(prev => !prev)}
        className={styles.input}
      />

      {value.length > 2 &&
        <div className={styles.count}>{value.length}</div>
      }

      {opened && (
        <div className={styles.dropdown}>
          <div
            className={styles.dropdownItem}
          >
            <Checkbox
              id="all"
              checked={value.length === options.length}
              onChange={(e) => selectHandler(e, "all")}
              className={styles.checkboxAll}
            >
              Выбрать все
            </Checkbox>
          </div>

          {options.map((option) => (
            <div
              key={option.id}
              className={styles.dropdownItem}
            >
              <Checkbox
                id={option.id}
                checked={value.find(id => id === option.id) ? true : false}
                onChange={(e) => selectHandler(e, option.id)}
                className={styles.checkbox}
              >
                <div className={styles.color} style={{ backgroundColor: option.color }}>
                  {option.nameForCustomer}
                </div>
              </Checkbox>
            </div>
          ))}
          {breakpoint === "mobile" &&
            <div className={styles.buttons}>
              <Button onClick={() => setOpened(false)}>Применить</Button>
              <Button className={styles.reset} onClick={() => {

                if (onSelect) {
                  onSelect([]);
                }
                setOpened(false);
              }}>Сбросить</Button>
            </div>
          }
        </div>
      )}
    </div>
  );
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./buttonInfo.module.scss";

interface IButtonInfo {
  children?: ReactNode;
  onClick?: () => void;
  breakpoint?: string;
}

export const ButtonInfo: FC<IButtonInfo> = ({
  children = "",
  onClick,
  breakpoint = "desktop",
}) => {
  return (
    <button
      className={classNames(styles.button, styles[breakpoint])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

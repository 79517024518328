import { FC } from "react";
import classNames from "classnames";

import { useUser } from "context/UserContext";
import { Button } from "components/Button";
import { useModal } from "hooks/useModal";
import { VinRequest } from "pages/Search/Manager/VinRequest";
import { useBreakpoint } from "hooks/useBreakpoint";
import { cleanPhone } from "utils/cleanPhone";

import styles from "./managerBig.module.scss";


export const ManagerBig: FC = () => {
  const { user } = useUser();
  const { breakpoint } = useBreakpoint();
  const [, setShown, Modal] = useModal();
  return (
    <div className={classNames(styles.manager, styles[breakpoint])}>
      <div className={styles.managerBlock}>
        <div className={styles.photo}>
          {user.admin?.avatar ? (
            <img src={user.admin?.avatar} alt="Менеджер" />
          ) : (
            <div className={styles.noavatar} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.name}>{user.admin?.name}</div>
          <div className={styles.title}>Ваш личный менеджер</div>
        </div>
      </div>
      <div className={styles.contactsBlock}>
        {user.admin?.phone && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`tel:${cleanPhone(user.admin?.phone)}`}
            className={styles.phone}
          >
            {user.admin?.phone}
          </a>
        )}
        {user.admin?.email && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${user.admin?.email}`}
            className={styles.email}
          >
            {user.admin?.email}
          </a>
        )}
        {user.admin?.phone && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send/?phone=${cleanPhone(
              user.admin?.phone
            )}`}
            className={styles.whatsapp}
          >
            {user.admin?.phone}
          </a>
        )}
      </div>
      <div className={styles.vin}>
        <Button
          className={styles.button}
          onClick={() => setShown(true)}
          breakpoint={breakpoint}
        >
          Запрос по VIN
        </Button>
        <Modal>
          <VinRequest/>
        </Modal>
      </div>
    </div>
  );
};

import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./body.module.scss";

interface IBody {
  children: ReactNode;
  className?: string;
}

export const Body: FC<IBody> = ({ children, className }) => {
  const { breakpoint } = useBreakpoint();
  return (
    <div className={classNames(styles.body, styles[breakpoint], className)}>
      {children}
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import { Button } from "components/Button";

import styles from "./resetPasswordResult.module.scss";

interface IResetPassword {
  setShownModalResult: (shownModalResult: boolean) => void;
  breakpoint?: string;
}

export const ResetPasswordResult: FC<IResetPassword> = ({
  setShownModalResult,
  breakpoint = "desktop"
}) => {
  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.title}>Восстановление пароля</div>
      <div className={styles.text}>
        На указанный E-mail будет отправлена ссылка на восстановление пароля
      </div>
      <Button
        onClick={() => setShownModalResult(false)}
        className={styles.button}
        breakpoint={breakpoint}
      >
        ОК
      </Button>
    </div>
  );
};

import { toast } from "react-toastify";
import { IQuery, ISearchResult } from "services/priceApi";
import { axiosInstance2 } from "services/api";
import { AppDispatch } from "store/store";

import { searchSlice } from "./SearchSlice";

export const fetchSearch = ({ article, brand, userId }: IQuery) => async (dispatch: AppDispatch) => {
  try {
    const url = brand
      ? `/v3/search/${article}/${brand}`
      : `/v3/search/${article}`;

    const search = async (provider?: string) => {
      const responce = await axiosInstance2.get<ISearchResult>(url, {
        params: {
          uid: userId,
          provider: provider || ""
        }
      });

      if (responce.data?.group) {
        dispatch(searchSlice.actions.setSuggestionBrands(responce.data?.group));
      } else if (responce.data?.data) {
        dispatch(searchSlice.actions.set(responce.data));
      }
    };

    dispatch(searchSlice.actions.cleanResult());

    dispatch(searchSlice.actions.setSearchLoading(true));

    await search();

    dispatch(searchSlice.actions.setSearchLoading(false));

    search("omega");
    search("corona");
    search("armtek");
    search("favorit");
    search("forum");

  } catch (e: any) {
    toast.error(e.response?.data?.message);
    dispatch(searchSlice.actions.setSearchLoading(false));
  }
};

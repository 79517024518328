import { TOffer } from "pages/Search/Offer";

import { axiosInstance2, axiosInstance } from "./api";

export const suggestion = async ({ search }: { search: string }) => {
  const res = await axiosInstance2.get(`/v3/suggestion/${search}`);
  return res.data;
};

export type TBrand = {
  brand: string;
  name: string;
  partNumber: string;
};

export type TProductSearchValue = {
  name: string;
  nomenclatureId: string;
  offer: TOffer[];
  shownMore?: number;
};

export type TBrandSearch = {
  [key: string]: TProductSearch;
};

export type TProductSearch = {
  [key: string]: TProductSearchValue;
};

export type TProductSearchArray = [string, TProductSearchValue];

export type TBrandSearchArray = [string, TProductSearch];
export interface ISearchResult {
  data: TBrandSearch;
  info: {
    partNumber: string;
    brand: string;
    image?: string;
    nomenclatureId?: string;
  };
  bestOffers: {};
  group?: []
};

export interface IQuery {
  article: string;
  brand?: string | null;
  userId: string;
  provider?: string;
};

// export const searchAPI = createApi({
//   reducerPath: "searchAPI",
//   baseQuery: fetchBaseQuery({ baseUrl: baseUrl2 }),
//   endpoints: (build) => ({
//     fetchAllSearch: build.query<ISearchResult, IQuery>({
//       query: ({ article, brand, userId, provider = "" }) => {

//         return {
//           url: brand
//             ? `/v3/search/${article}/${brand}`
//             : `/v3/search/${article}`,
//           params: {
//             uid: userId,
//             provider
//           }
//         };
//       }
//     })
//   })
// });

// export const searchPrice = async ({
//   article,
//   brand,
//   userId,
//   provider = ""
// }: {
//   article: string;
//   brand?: string | null;
//   userId?: string;
//   provider?: string;
// }) => {
//   const url = brand
//     ? `/v3/search/${article}/${brand}`
//     : `/v3/search/${article}`;

//   const res = await axiosInstance2.get(url, {
//     params: {
//       uid: userId,
//       provider
//     }
//   });
//   return res.data;
// };

export const getNomenclature = async (id: string) => {
  const params = {
    id
  };
  const res = await axiosInstance.get("/nomenclature.getOneClient", { params });
  return res.data;
};

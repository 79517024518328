import { FC, useState } from "react";
import classNames from "classnames";
import { object, string } from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { Button } from "components/Button";
import { Input } from "components/Input";
import { addAddress } from "services/userApi";
import { Spinner2 } from "components/Spinner2";

import { TAddress } from "../Addresses";

import styles from "./addAddressModal.module.scss";

const NewAddressSchema = object().shape({
  address: string().required(),
});

interface IAddAddressModal {
  setAddresses: (addresses: TAddress[]) => void;
  setShownAddAddress: (shown: boolean) => void;
  breakpoint?: string;
}

export const AddAddressModal: FC<IAddAddressModal> = ({
  setAddresses,
  setShownAddAddress,
  breakpoint = "desktop"
}) => {
  const [loading, setLoading] = useState(false);

  const addAddressHandler = (
    values: { address: string },
    resetForm: () => void) => {

    setLoading(true);

    addAddress({ address: values.address })
      .then((res) => {
        setAddresses(res);
        toast.success("Адрес успешно добавлен");
        setShownAddAddress(false);
        resetForm();
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setLoading(false);
      });
  }

  return <div className={classNames(styles.wrapper, styles[breakpoint])}>
    {loading && <Spinner2 overlay />}
    <div className={styles.title}>Добавить адрес доставки</div>
    <div className={styles.subTitle}>
      Введите адрес доставки
    </div>

    <Formik
      initialValues={{
        address: "",
      }}
      validationSchema={NewAddressSchema}
      onSubmit={(values, { resetForm }) => {
        addAddressHandler(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Input
            id="address"
            label="Адрес"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            className={styles.input}
            error={
              errors.address && touched.address ? errors.address : null
            }
          />
          <div className={styles.buttonBlock}>
            <Button
              type="submit"
              className={styles.enter}
              breakpoint={breakpoint}
            >
              Добавить адрес
            </Button>
          </div>

        </form>
      )}
    </Formik>
  </div>
}
import { FC, ChangeEvent, ReactNode } from "react";
import classNames from "classnames";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./radioItem.module.scss";

interface IRadioItemOne {
  children?: ReactNode;
  id: string;
  title: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string | false;
  subtitle?: string;
  className?: string;
  name?: string;
  value?: string;
}

export const RadioItem: FC<IRadioItemOne> = ({
  children,
  id,
  name,
  title,
  onChange,
  subtitle = "",
  className,
  error,
  value,
}) => {
  const { breakpoint, isDesktop, isMobile } = useBreakpoint();
  return (
    <div className={classNames(styles.radioItemWrapper, styles[breakpoint])}>
      <div
        className={classNames(
          styles.radioItem,
          { [styles.error]: error },
          className
        )}
      >
        <input
          type="radio"
          id={`${name}_${id}`}
          name={name}
          onChange={onChange}
          checked={value === id}
          value={id}
        />
        <label htmlFor={`${name}_${id}`}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subTitle}>{subtitle}</div>}
          </div>
        </label>
      </div>
      {children}
    </div>
  );
};

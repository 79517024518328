import { FC, useEffect, useRef, useState } from "react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import classNames from "classnames";

import { getWindowDimensions, useBreakpoint } from "hooks/useBreakpoint";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./banners.module.scss";
import banner_02 from "./banner_02.png";
import banner_08 from "./banner_08.png";
import banner_04 from "./banner_04.png";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export const Banners: FC = () => {

  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const { breakpoint, isDesktop } = useBreakpoint();
  const [slidePerWiew, setSlidePerWiew] = useState(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {

    function handleResize() {
      const { width } = getWindowDimensions();

      if (breakpoint === "desktop") {
        setSlidePerWiew((width - 695) / 350);
      }

      if (breakpoint === "tablet") {
        setSlidePerWiew((width - 300) / 350);
      }

      if (breakpoint === "mobile") {
        setSlidePerWiew(width / 353);
      }
    }
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  useEffect(() => {
    if (swiper !== null) {
      setTimeout(() => swiper.slideTo(0), 100);
    }
  }, [swiper]);

  return <div className={classNames(styles.swiperWrapper, styles[breakpoint])}>
    <Swiper
      spaceBetween={0}
      loop={true}
      slidesPerView={slidePerWiew}
      className={styles.caroucel}
      modules={[Navigation, Thumbs]}
      centeredSlides={breakpoint === "mobile"}
      onInit={(swiper: SwiperCore): void => {
        if (typeof swiper.params.navigation !== "boolean") {
          const navigation = swiper.params.navigation;
          if (navigation) {
            navigation.prevEl = navigationPrevRef.current;
            navigation.nextEl = navigationNextRef.current;
          }
        } else {
          swiper.params.navigation = false;
        }
        swiper.navigation.init();
        swiper.navigation.update();
        setSwiper(swiper);
      }}
    >
      {isDesktop &&
        <div className={styles.prevButton} ref={navigationPrevRef}><ArrowIcon /></div>
      }
      <div className={styles.slides}>
        {[banner_02, banner_08, banner_04].map((item, i) =>
          <SwiperSlide key={i} className={styles.slide}>
            <img src={item} alt="" />
          </SwiperSlide>)}
      </div>
      {isDesktop &&
        <div className={styles.nextButton} ref={navigationNextRef}><ArrowIcon /></div>
      }
    </Swiper>
  </div>;
};
import { FC } from "react";
import classNames from "classnames";

import styles from "./noImage.module.scss";

interface INoImage {
  className?: string;
}

export const NoImage: FC<INoImage> = ({ className }) => (
  <div className={classNames(styles.noImage, className)}>
    <div />
  </div>
);

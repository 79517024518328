export const quantityValid: (value: number, max: number) => number = (
  value: number,
  max: number
) => {
  const reg = new RegExp("^[0-9]*$");

  if (reg.test(value.toString()) === false) return 1;
  if (+value < 1) return 1;
  if (+value > 9999) return 9999;
  if (!max.toString().includes(">")) {
    if (+value > max) return max;
  }
  return +value;
};

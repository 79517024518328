import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import styles from "./goBack.module.scss";

interface IGoBack {
  breakpoint?: string;
}

export const GoBack: FC<IGoBack> = ({ breakpoint = "desktop" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className={classNames(styles.goBack, styles[breakpoint])}>
      <button onClick={() => navigate(-1)}>
        <div className={styles.icon} />
        Назад
      </button>
    </nav>
  );
};

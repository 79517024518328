import { FC, ReactNode } from "react";
import classNames from "classnames";
import { getExist } from "utils/getExist";

import styles from "./quantityIcon.module.scss";

interface IQuantityIcon {
  children: ReactNode;
  exist: string;
  className?: string;
}

export const QuantityIcon: FC<IQuantityIcon> = ({
  children,
  exist,
  className
}) => {

  return (
    <div
      className={classNames(styles.delivery, className, {
        [styles.many]: getExist(exist) >= 10,
        [styles.mean]: getExist(exist) >= 5 && getExist(exist) < 10,
        [styles.few]: getExist(exist) > 0 && getExist(exist) < 5
      })}
    >
      {children}
    </div>
  );
};

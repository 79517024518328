import { FC } from "react";
import classNames from "classnames";

import styles from "./spinner.module.scss";

interface ISpinner {
  className?: string;
}

export const Spinner: FC<ISpinner> = ({ className }) => (
  <div className={classNames(styles.spinner, className)}>
    <div className={styles.icon}>
      <span />
      <span />
      <span />
    </div>
  </div>
);

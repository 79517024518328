import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { AccountLayout, IAccount } from "layout/AccountLayout";
import { AuthLayout, IAuth } from "layout/AuthLayout";

import { IRoute, accountRoutes, authRoutes } from "../routes";

const childRoutes = (Layout: FC<IAccount | IAuth>, routes: IRoute[]) =>
  routes.map(({ path, component: Component, title, breadCrumbs }, index) => (
    <Route
      key={index}
      path={path}
      element={
        <Layout breadCrumbs={breadCrumbs}>
          <Component />
        </Layout>
      }
    />
  ));

export const RoutesRender = () => (
  <Routes>
    {childRoutes(AccountLayout, accountRoutes)}
    {childRoutes(AuthLayout, authRoutes)}
    <Route path="*" element={<>404</>} />
  </Routes>
);

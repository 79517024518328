import { FC, useState, useEffect, ReactNode } from "react";
import classNames from "classnames";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ReactComponent as PlusIcon } from "assets/img/svg/plus.svg";
import { addAddress } from "services/userApi";
import { toast } from "react-toastify";
import { TAddress } from "pages/Profile/Addresses";
import { Spinner2 } from "components/Spinner2";
import { TDelivery } from "pages/OrderOne";

import styles from "./selectAddress.module.scss";

interface ISelectAddress {
  addresses: TAddress[];
  setShownAddressModal: (shownAddressModal: boolean) => void;
  onSelect: (address: TAddress | null) => void;
  address: TAddress | null;
  delivery: string | null;
  breakpoint?: string;
  renderDelivaryArea: () => void;
  renderAddress: (address: TAddress, delivery: string | null) => void;
}

const filterAddress = (items: TAddress[], delivery: string | null) => {
  if (delivery === "2") {
    return items.filter(v => !v.transportCompany);
  }
  if (delivery === "3") {
    return items.filter(v => v.transportCompany);
  }
  return items;
};

export const SelectAddress: FC<ISelectAddress> = ({
  addresses,
  setShownAddressModal,
  onSelect,
  address,
  delivery,
  breakpoint = "desktop",
  renderDelivaryArea,
  renderAddress
}) => {
  const [items, setItems] = useState<TAddress[]>(filterAddress(addresses, delivery) || []);

  useEffect(() => {
    setItems(filterAddress(addresses, delivery));
  }, [addresses]);

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.title}> {delivery === "2" ? "Адрес доставки" : "Транспортная компания"}</div>
      <div className={styles.subTitle}>Выберите {delivery === "2" ? "адрес доставки" : "транспортную компанию"}</div>
      <div className={styles.addressesList}>
        {items.length > 0 ? items.map((item) => (
          <div
            key={item.id}
            className={classNames(styles.addressItem, {
              [styles.active]: item.id === address?.id
            })}
            onClick={() => {
              onSelect(item);
              setShownAddressModal(false);
            }}
          >
            <div className={styles.radio} />
            {renderAddress(item, delivery) as ReactNode}
          </div>
        ))
          : <>{`Актуального адреса для типа доставки: 
            ${delivery === "3" ? "Доставка ТК" : renderDelivaryArea()} не найдено,
            обратитесь к ответственному менеджеру.`}</>}
      </div>
    </div>
  );
};

import { FC, ReactNode, useState, Children, useEffect } from "react";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./accordeon.module.scss";

interface IAccordeon {
  children: ReactNode[];
  titles: string[];
}

export const Accordeon: FC<IAccordeon> = ({ children, titles }) => {
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    const ids = Children.map(children, (child, i) => {
      return 0;
    });
    setSelected(ids || []);
  }, []);

  return (
    <div className={styles.wrapper}>
      {Children.map(children, (child, i) => (
        <div
          key={i}
          className={classNames(styles.item, {
            [styles.shown]: selected[i] === 1,
          })}
          onClick={() => setSelected(prev => {     
            prev[i] = prev[i] === 1 ? 0 : 1;
            return [...prev];
          })}
        >
          <div className={styles.title}>
            <>{titles[i]}</>
            <div className={styles.arrow}><ArrowIcon /></div>
          </div>
          {selected[i] === 1 && <div className={styles.content}>{child}</div>}
        </div>
      ))}
    </div>
  );
};

import axios from "axios";
export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_DEV_URL;

export const baseUrl2 =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL2
    : process.env.REACT_APP_BASE_DEV_URL2;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" }
});

export const axiosInstance2 = axios.create({
  baseURL: baseUrl2,
  headers: { "Content-Type": "application/json" }
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("clientAccessToken") != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "clientAccessToken"
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  async (e) => {
    const originalRequest = e.config;

    if (
      e?.response?.status === 401 &&
      e?.config &&
      !originalRequest?._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const data = new URLSearchParams();
        data.append("grantType", "refreshToken");
        const refreshToken = localStorage.getItem("clientRefreshToken") || "";
        data.append("refreshToken", refreshToken);
        const res = await axios.post(`${baseUrl}/requestToken`, data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });
        localStorage.setItem("clientAccessToken", res.data.accessToken);
        localStorage.setItem("clientRefreshToken", res.data.refreshToken);

        return axiosInstance.request(originalRequest);
      } catch (e) {
        localStorage.removeItem("clientAccessToken");
        localStorage.removeItem("clientRefreshToken");
        window.location.href = "/login";
      }
    }
    throw e;
  }
);

import { FC } from "react";
import classNames from "classnames";

import { ReactComponent as HeartIcon } from "assets/img/svg/heart.svg";

import styles from "./favorite.module.scss";

interface IFavoriteButton {
  onClick: () => void;
  active?: boolean;
  className?: string;
}

export const FavoriteButton: FC<IFavoriteButton> = ({
  onClick,
  active = false,
  className
}) => {
  return (
    <button
      className={classNames(styles.favorite, className, { [styles.active]: active })}
      onClick={onClick}
    >
      <HeartIcon />
    </button>
  );
};

import { FC, ChangeEvent } from "react";

import styles from "./input.module.scss";

interface IInput {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  placeholder?: string;
}

export const InputFilter: FC<IInput> = ({ onChange, value, placeholder }) => {
  return (
    <div className={styles.input}>
      <input
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

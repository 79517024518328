import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";
import brands from "assets/img/png/brands.png";

import styles from "./authWrapper.module.scss";

interface IAuthWrapper {
    children: ReactNode;
}

export const AuthWrapper: FC<IAuthWrapper> = ({ children }) => {
    const { breakpoint, isMobile, isDesktop } = useBreakpoint();
    return <div className={classNames(styles.wrapper, styles[breakpoint])}>
        <div className={styles.leftBlock}>
            <div className={styles.form}>
                {children}
            </div>
        </div>

        {isDesktop && (
            <div className={styles.rightBlock}>
                <div className={styles.title}>
                    Что можно купить после регистрации
                </div>
                <div className={styles.image}>
                    <img src={brands} alt="Бренды" />
                </div>
            </div>
        )}
    </div>
}
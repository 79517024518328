import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { TBalanceItem } from "pages/Balance";
import { dateFormat, priceFormat } from "utils/format";
import { getBalance } from "services/balanceApi";

import styles from "./balanceWidget.module.scss";

const PAGE_SIZE = 5;

export const BalanceWidgeet: FC = () => {
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAndSetBalance = (refresh?: boolean) => {
    setLoading(true);
    getBalance({
      paginationCount: PAGE_SIZE,
      paginationOffset: refresh ? 0 : balance?.length,
    })
      .then((res) => {
        setBalance(res.items);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetBalance();
  }, []);

  const BalanceItem = ({ row }: { row: TBalanceItem }) => {
    return <div className={styles.balanceItem} key={row.id}>
      <div>{row.typeCustomer || row.type}</div>
      <div>{dateFormat(row.date)}</div>
      <div>{row.number}</div>
      <div className={classNames({
        [styles.incoming]: row.summ > 0,
        [styles.outgoing]: row.summ < 0,
      })}>{row.summ > 0 && "+"}{priceFormat(row.summ)}</div>
    </div >;
  };

  return <div className={styles.balances}>
    <div className={styles.top}>
      <div className={styles.title}>Последние взаиморасчёты</div>
    </div>

    <div className={styles.table}>
      <div className={styles.balanceHead}>
        <div>Документ</div>
        <div>Дата документа</div>
        <div>Номер документа</div>
        <div>Сумма</div>
      </div>
      <div>

        {balance.length > 0 ? balance.map((row: TBalanceItem) => (
          <BalanceItem row={row} key={row.id} />
        )) :
          <div className={styles.notFound}>Пока нет записей</div>
        }
      </div>
    </div>
    {balance.length > 0 && <div>
      <Link to="/balance" className={styles.more}>Показать все</Link>
    </div>}

  </div>;
}
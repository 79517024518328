import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Card } from "components/Card";
import { useBreakpoint } from "hooks/useBreakpoint";
import { priceFormat } from "utils/format";
import { useUser } from "context/UserContext";

import styles from "./balanceCard.module.scss";

interface IBalanceCard {
  balance: number;
  overdueDebt: number;
}

export const BalanceCard: FC<IBalanceCard> = ({ balance, overdueDebt }) => {
  const { breakpoint } = useBreakpoint();
  const { user } = useUser();
  return <Card
    className={classNames(styles.card, styles.balance)}
    breakpoint={breakpoint}
  >
    <div className={styles.top}>
      <div className={styles.rubleIcon} />
      <div className={styles.balanceBlock}>
        <span>Баланс</span>
        <div className={styles.summ}>
          {priceFormat(balance)} ₽
        </div>
      </div>
      <div>
        <div className={styles.bonusInfo}><div className={styles.diamond} />{priceFormat(user?.bonus)}</div>
      </div>
    </div>
    {overdueDebt > 0 &&
      <Link to="/paymentShedule" className={styles.bottom}>
        Просроченный долг <div>{priceFormat(overdueDebt)} ₽</div>
      </Link>
    }
  </Card>;
};
import { TAnalogues } from "pages/Search";
import { TOffer } from "pages/Search/Offer";

interface ISortResult {
  items: TOffer[];
  sort?: string;
}

interface ISortResultProducts {
  items: TAnalogues[];
  sort?: string;
}

export const sortResult = ({ items, sort = "default" }: ISortResult) => {
  const arrayForSort = [...items];
  if (sort === "probability") {
    return arrayForSort.sort(function (a, b) {
      if (a.inStock === false && b.inStock === true) {
        return 1;
      }

      if (a.inStock === true && b.inStock === false) {
        return -1;
      }

      if (a.probability < b.probability) {
        return 1;
      }
      if (a.probability > b.probability) {
        return -1;
      }

      return 0;
    });
  }
  if (sort === "deliveryTime") {
    return arrayForSort.sort(function (a, b) {
      if (a.inStock === false && b.inStock === true) {
        return 1;
      }

      if (a.inStock === true && b.inStock === false) {
        return -1;
      }

      if (a.deliveryTime > b.deliveryTime) {
        return 1;
      }
      if (a.deliveryTime < b.deliveryTime) {
        return -1;
      }

      return 0;
    });
  }

  if (sort === "price") {
    return arrayForSort.sort(function (a, b) {
      if (a.inStock === false && b.inStock === true) {
        return 1;
      }

      if (a.inStock === true && b.inStock === false) {
        return -1;
      }

      if (a.price > b.price) {
        return 1;
      }
      if (a.price < b.price) {
        return -1;
      }

      return 0;
    });
  }

  return arrayForSort.sort(function (a, b) {
    if (a.inStock === false && b.inStock === true) {
      return 1;
    }

    if (a.inStock === true && b.inStock === false) {
      return -1;
    }

    if (a.price && b.price) {
      if (a.price > b.price) {
        return 1;
      }
      if (a.price < b.price) {
        return -1;
      }
    }
    return 0;
  });
};

export const sortResultAnalog = ({
  items,
  sort = "default"
}: ISortResultProducts) => {
  const arrayForSort = [...items];
  return arrayForSort.sort(function (a, b) {
    if (a.inStock === false && b.inStock === true) {
      return 1;
    }

    if (a.inStock === true && b.inStock === false) {
      return -1;
    }

    return 0;
  });
};

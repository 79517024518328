import { FC } from "react";
import classNames from "classnames";

import styles from "./successOrderModal.module.scss";

export type TOrderResult = {
  number?: number;
  delivery?: string;
  payment?: string;
};

interface ISuccessOrderModal {
  order?: TOrderResult | null;
  breakpoint?: string;
}

export const SuccessOrderModal: FC<ISuccessOrderModal> = ({
  order,
  breakpoint = "desktop"
}) => {
  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.title}>Спасибо за ваш заказ</div>
      <div className={styles.subTitle}>
        Наш менеджер свяжется с вами в ближайшее время
      </div>
      <div className={styles.item}>
        <div>Номер заказа:</div>
        <div>№ {order?.number}</div>
      </div>
      <div className={styles.item}>
        <div>Способ оплаты:</div>
        <div>{order?.payment}</div>
      </div>
      <div className={styles.item}>
        <div>Способ доставки:</div>
        <div>{order?.delivery}</div>
      </div>
    </div>
  );
};

export const locale = {
  mixed: {
    required: "Поле обязательно для заполнения",
    oneOf: "Поле обязательно для заполнения"
  },
  string: {
    email: "Email должен быть вида example@gmail.com",
    min: "Слишком короткое значение",
    max: "Слишком длинное значение"
  }
};

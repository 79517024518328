import { FC } from "react";

import { Card } from "components/Card";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useUser } from "context/UserContext";
import { priceFormat } from "utils/format";

import styles from "./bonusCard.module.scss";

export const BonusCard: FC = () => {
  const { breakpoint } = useBreakpoint();
  const { user } = useUser();

  return <Card className={styles.bonus} breakpoint={breakpoint}>
    <div className={styles.top}>
      <div className={styles.diamondIcon} />
      <div className={styles.balanceBlock}>
        <span>Бонусы</span>
        <div className={styles.summ}>
          {priceFormat(user?.bonus)}
        </div>
      </div>
      <div>
        <div className={styles.bonusInfo}>1 бонус = 1 ₽</div>
      </div>
    </div>
    {/* <div className={styles.bottom}>Еще 140 бонусов зачислится 10 марта</div> */}
  </Card>;
};
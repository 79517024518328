import { FC, useRef } from "react";
import { Link } from "react-router-dom";

import { useBreakpoint } from "hooks/useBreakpoint";
import { TNomenclature } from "pages/OrderOne";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./related.module.scss";

export type TRelated = {
  id: string;
  nomenclature: TNomenclature;
};

interface IRelated {
  products: TRelated[];
}

export const Related: FC<IRelated> = ({ products }) => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const { breakpoint, isDesktop } = useBreakpoint();


  const renderRelated = () =>
    <Swiper
      spaceBetween={4}
      className={styles.caroucel}
      modules={[Navigation]}
      freeMode={true}
      onInit={(swiper: SwiperCore): void => {
        if (typeof swiper.params.navigation !== "boolean") {
          const navigation = swiper.params.navigation;
          if (navigation) {
            navigation.prevEl = navigationPrevRef.current;
            navigation.nextEl = navigationNextRef.current;
          }
        } else {
          swiper.params.navigation = false;
        }
        swiper.navigation.init();
        swiper.navigation.update();
      }}
      breakpoints={{
        320: {
          slidesPerView: 1.5
        },
        720: {
          slidesPerView: 2
        }
      }}
    >
      {isDesktop &&
        <div className={styles.prevButton} ref={navigationPrevRef}><ArrowIcon /></div>
      }

      <div className={styles.swiper}>
        {products.map((product, i) => (
          <SwiperSlide key={product.id} className={styles.slide}>
            <div className={styles.product}>
              <div className={styles.image}>
                {product.nomenclature.images.length > 0 && (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${product.nomenclature.images[0].key}`}
                    alt="Товар"
                  />
                )}
              </div>
              <div className={styles.info}>
                <div className={styles.article}>
                  {product.nomenclature.brand}
                  <span>{product.nomenclature.article}</span>
                </div>
                <Link
                  to={`/search?article=${product.nomenclature.article}&brand=${product.nomenclature.brand}`}
                  className={styles.name}
                >
                  {product.nomenclature.name}
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </div>
      {isDesktop &&
        <div className={styles.nextButton} ref={navigationNextRef}><ArrowIcon /></div>
      }
    </Swiper>;

  return (
    <div className={styles[breakpoint]}>
      <div className={styles.title}>С этим товаром покупают</div>
      <div className={styles.swiperWrapper}>

        {products.length > 0 ?
          renderRelated() :
          <div>
            Сопутствующих товаров пока нет
          </div>
        }
      </div>
    </div>
  );
};

import { axiosInstance } from "./api";

export const getPaymentShedule = async () => {
  const res = await axiosInstance.get("/analiticsReceivables.getCustomer");
  return res.data;
};

// export type TCreteOrder = {
//   delivery: string;
//   payment: string;
//   deliverySettings: string;
//   address?: string;
//   ids: string[];
//   commentClient: string;
//   client: boolean;
// };

// export const createOrder = async (props: TCreteOrder) => {
//   const res = await axiosInstance.post("/orders.create", props);
//   return res.data;
// };

// export type TGetOrders = {
//   paginationCount: number;
//   paginationOffset: number;
//   search?: string;
//   dateStart?: string;
//   dateEnd?: string;
//   number?: string;
//   status?: string | null;
// };

// export const getOrders = async (props: TGetOrders) => {
//   const params = {
//     ...props,
//     "pagination[count]": props.paginationCount,
//     "pagination[offset]": props.paginationOffset,
//   };

//   const res = await axiosInstance.get("/orders.getCustomerOrders", { params });
//   return res.data;
// };

// export const getStatuses = async () => {
//   const res = await axiosInstance.get("/orders.getStatuses");
//   return res.data;
// };

// export const getOrder = async (id: string) => {
//   const params = {
//     id,
//   };
//   const res = await axiosInstance.get("/orders.getOne", { params });
//   return res.data;
// };

// export type TGetUpd = {
//   id: string;
// };

// export const getUpd = async (props: TGetUpd) => {
//   const res = await axiosInstance.post("/orders.getUpd", props, {
//     responseType: "blob",
//   });
//   return res.data;
// };

// export type TGetInvoice = {
//   id: string;
//   withSign?: boolean;
// };

// export const getInvoice = async (props: TGetInvoice) => {
//   const res = await axiosInstance.post("/orders.getInvoice", props, {
//     responseType: "blob",
//   });
//   return res.data;
// };

// export type TGetCheck = {
//   id: string;
// };

// export const getCheck = async (props: TGetCheck) => {
//   const res = await axiosInstance.post("/orders.getCheck", props, {
//     responseType: "blob",
//   });
//   return res.data;
// };

// export type TVinRequest = {
//   vin: string;
//   comment: string;
// };

// export const vinRequest = async (props: TVinRequest) => {
//   const res = await axiosInstance.post("/orders.sendVinRequest", props);
//   return res.data;
// };

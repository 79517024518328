import { FC } from "react";
import classNames from "classnames";

import styles from "./providerPopup.module.scss";

interface IProviderPopup {
  percent: number;
  className?: string;
}

export const ProviderPopup: FC<IProviderPopup> = ({ percent, className }) => {

  const getOffset = () => {
    const progress = percent / 100;
    const dashoffset = 340 * (1 - progress);
    return dashoffset;
  };

  return (
    <div className={classNames(styles.providerPopup, className)}>
      <div className={styles.progressContainer}>

        <svg className={styles.progress} width="52" height="52" viewBox="0 0 120 120">
          <circle className={classNames(
            styles.meter,
            {
              [styles.green]: percent >= 90,
              [styles.orange]: percent >= 60 && percent < 90,
              [styles.red]: percent < 60
            }
          )}
          cx="60" cy="60" r="54" strokeWidth="6" />
          <circle className={classNames(
            styles.value,
            {
              [styles.green]: percent >= 90,
              [styles.orange]: percent >= 60 && percent < 90,
              [styles.red]: percent < 60
            }
          )} cx="60" cy="60" r="54" strokeWidth="6"
          style={{ strokeDashoffset: getOffset() }} />
        </svg>
        <div className={styles.progressPercent}>{percent}%</div>
      </div>

      <div className={styles.info}>
        <div className={styles.title}>Вероятность поставки</div>
        <div className={styles.subTitle}>
          {percent}% заказов от этого поставщика приходят в срок.
        </div>
      </div>
    </div>
  );
};

import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { object, string, boolean } from "yup";
import { useSwipeable, SwipeEventData } from "react-swipeable";

import { createOrder } from "services/orderApi";
import { useCart } from "context/CartContext";
import { useUser } from "context/UserContext";
import { Radio } from "components/Radio";
import { RadioItem } from "components/RadioItem";
import classNames from "classnames";
import { priceFormat } from "utils/format";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { useBreakpoint } from "hooks/useBreakpoint";
import { TAddress } from "pages/Profile/Addresses";
import { useModal } from "hooks/useModal";
import { toast } from "react-toastify";
import { openAddress } from "utils/openAddress";
import { ReactComponent as MapIcon } from "assets/img/svg/map.svg";
import { ReactComponent as PlusIcon } from "assets/img/svg/plus.svg";
import { getExist } from "utils/getExist";

import { TCart } from "../Cart";
import { SuccessOrderModal, TOrderResult } from "../SuccessOrderModal";

import styles from "./orderForm.module.scss";
import { ReactComponent as DeliveryIcon } from "./delivery.svg";
import { ReactComponent as CommentIcon } from "./comment.svg";
import { ReactComponent as PayIcon } from "./pay.svg";
import { SelectAddress } from "./SelectAddress";

const OrderSchema = object().shape({
  delivery: string().required(),
  payment: string().required(),
  agreement: boolean().oneOf([true])
});

type TCreateOrder = {
  payment: string;
  delivery: string;
  comment: string;
  agreement: boolean;
};

interface IOrderForm {
  setSelected: (selected: string[]) => void;
  selected: string[];
  cartSumm: number;
  cartQuantity: number;
}

export const OrderForm: FC<IOrderForm> = ({ setSelected, selected, cartSumm, cartQuantity }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { breakpoint, isDesktop } = useBreakpoint();
  const { cart, setCart, setAdded, setCartLoading } = useCart();
  const { user } = useUser();
  const [minimizeOrderBlock, setMinimizeOrderBlock] = useState<boolean>(true);
  const [address, setAddress] = useState<TAddress | null>(null);
  const [order, setOrder] = useState<TOrderResult | null>(null);
  const [, setShownSuccessModal, SuccessModal] = useModal();
  const [, setShownAddressModal, AddressModal] = useModal();

  const orderHandler = (values: TCreateOrder, resetForm: () => void) => {
    if (cart.find((item: TCart) => getExist(item.exist) < item.count)) {
      return;
    }

    setCartLoading(true);
    createOrder({
      delivery: values.delivery,
      payment: values.payment,
      address: address?.id || "",
      ids: selected,
      commentClient: values.comment,
      client: true
    })
      .then((res) => {
        resetForm();
        setSelected([]);
        setCart(res.cart);
        setAdded(null);
        setOrder({
          number: res.number,
          delivery: res.delivery,
          payment: res.payment
        });
        setShownSuccessModal(true);
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setCartLoading(false));
  };

  const SelectAddressWithButton = ({ delivery }: { delivery: string }) => (
    <>
      <Button
        onClick={() => setShownAddressModal(true)}
        className={styles.selectAddressButton}
        breakpoint={breakpoint}
      >
        <PlusIcon />
        Выбрать {delivery === "2" ? "адрес" : "ТК"}
      </Button>
    </>
  );

  useEffect(() => {
    const address = user?.addresses.find(
      (address: TAddress) => address.main === true
    );
    if (address) {
      setAddress(address);
    } else {
      setAddress(user?.addresses.length === 1 ? user?.addresses[0] : null);
    }
  }, [user]);

  const handleSwiped = (eventData: SwipeEventData) => {
    if (ref.current) {
      if (eventData.deltaY < -150) {
        setMinimizeOrderBlock(false);
      }
      if (eventData.deltaY > 150) {
        setMinimizeOrderBlock(true);
      }
      ref.current.style.removeProperty("max-height");
      ref.current.style.removeProperty("transform");
      ref.current.classList.remove(styles.noTransition);
    }
  };

  const handleSwiping = (eventData: SwipeEventData) => {
    if (ref.current) {
      ref.current.classList.add(styles.noTransition);
      if (eventData.dir === "Down") {
        ref.current.style.transform = `translateY(${eventData.deltaY}px)`;
      }
      if (eventData.dir === "Up") {
        if (minimizeOrderBlock) {
          ref.current.style.maxHeight = `${-eventData.deltaY + 90}px`;
        }
      }
    }
  };

  useEffect(() => {
    const body = document.body;
    if (minimizeOrderBlock === false) {
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
    }

  }, [minimizeOrderBlock]);

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    onSwiping: handleSwiping,
    onTouchStartOrOnMouseDown: (({ event }) => {

    }),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const renderPickupAddress = () => {
    return user?.branch[0]
      ? "г. Москва, ул. Нижние Поля, 31с1, склад ЛТ «ЛИДЕР ТРАК»"
      : "г. Воронеж, ул. Остужева, 45, склад ЛТ «ЛИДЕР ТРАК»";
  };

  const renderDelivaryArea = () => {
    return user?.branch[0]
      ? "Доставка курьером по Москве и МО"
      : "Доставка курьером по Воронежу и ВО";
  };

  const SeeAddressOnMap = ({
    delivery,
    address
  }: {
    delivery: string;
    address?: string;
  }) => (
    <a
      href={openAddress(
        delivery === "1"
          ? renderPickupAddress()
          : address || ""
      )}
      target="_blank"
      rel="noreferrer"
      className={styles.deliveryButton}
    >
      <MapIcon />
      Посмотреть на карте
    </a>
  );

  const Swipable = ({ children }: { children: ReactNode }) => {
    if (!isDesktop) {
      return <div className={classNames(styles.orderBlock, styles[breakpoint])} {...handlers}>{children}</div>;
    }
    return <div className={classNames(styles.orderBlock, styles[breakpoint])}>{children}</div>;
  };

  const OnError = ({ errors }: any) => {
    if (Object.entries(errors).length > 0) {
      setMinimizeOrderBlock(false);
    }
    return <></>;
  };

  const renderAddress = (address: TAddress, delivery: string | null) => {
    if (delivery === "2") {
      return `${address.city ?? ""}${address.city ? ", " : ""}${address.address ?? ""}`;
    };

    if (delivery === "3") {
      return `${address.transportCompany ? "ТК " : ""}
      ${address.transportCompany ?? ""}${address.transportCompany ? ", " : ""}
      ${address.city ?? ""}${address.city ? ", " : ""}${address.address ?? ""}`;
    };

    return address.address ?? "";
  };

  return <>
    {cart.length > 0 &&
      <Formik
        initialValues={{
          delivery: "",
          payment: user?.type === 2 ? "2" : "1",
          comment: "",
          agreement: true
        }}
        validationSchema={OrderSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          orderHandler(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <Swipable>
            <OnError errors={errors} />
            <form
              onSubmit={handleSubmit}
            >
              <div
                ref={ref}
                className={classNames(styles.orderTopBlock, {
                  [styles.show]: !minimizeOrderBlock
                })}
              >
                {!isDesktop && (
                  <div>
                    <div
                      className={styles.treangle}
                      onClick={() =>
                        setMinimizeOrderBlock((prev) => !prev)
                      }
                    />
                    <div className={styles.orderBlockTitle}>
                      Информация о заказе
                    </div>
                  </div>
                )}
                <div className={styles.overflow}>
                  <div className={styles.pay}>
                    <div className={styles.orderTitle}>
                      <PayIcon />
                      Способ оплаты
                    </div>
                    <Radio
                      onChange={(e) =>
                        setFieldValue("payment", e.target.value)
                      }
                      error={
                        errors.payment &&
                        touched.payment &&
                        errors.payment
                      }
                      name="payment"
                      value={values.payment}
                      className={classNames(styles.radio, styles.payment)}
                    >
                      <RadioItem
                        id="1"
                        title="Наличными"
                        value="1"
                        className={styles.radio}
                      />
                      <RadioItem
                        id="2"
                        title="Безналичный расчет"
                        value="2"
                      />
                    </Radio>
                    <div className={styles.annotation}>
                      {values.payment === "1" ? (
                        <>Вы можете оплатить наличными</>
                      ) : (
                        <>Вам будет выставлен счет</>
                      )}
                    </div>
                  </div>
                  <div className={styles.delivery}>
                    <div className={styles.orderTitle}>
                      <DeliveryIcon />
                      Способ доставки
                    </div>
                    <div className={styles.selectDelivery}>
                      <Radio
                        onChange={(e) => {
                          setFieldValue("delivery", e.target.value);
                        }}
                        error={
                          errors.delivery &&
                          touched.delivery &&
                          errors.delivery
                        }
                        name="delivery"
                        value={values.delivery}
                        className={styles.radio}
                      >
                        <RadioItem
                          id="1"
                          title="Самовывоз со склада"
                          subtitle={renderPickupAddress()}
                          value="1"
                          className={styles.radio}
                        >
                          <SeeAddressOnMap
                            delivery={values.delivery}
                            address={address?.address}
                          />
                        </RadioItem>

                        <RadioItem
                          id="2"
                          title={renderDelivaryArea()}
                          subtitle={address && !address?.transportCompany
                            ? renderAddress(address, "2")
                            : ""}
                          value="2"
                          className={styles.radioDelivery}
                        >
                          {values.delivery === "2" &&
                            <SelectAddressWithButton
                              delivery={values.delivery}
                            />
                          }
                        </RadioItem>

                        <RadioItem
                          id="3"
                          title="Доставка ТК"
                          subtitle={address?.transportCompany ? renderAddress(address, "3") : ""}
                          value="3"
                        >
                          {values.delivery === "3" &&
                            <SelectAddressWithButton
                              delivery={values.delivery}
                            />
                          }
                        </RadioItem>
                      </Radio>
                    </div>
                  </div>
                  <div className={styles.comment}>
                    <div className={styles.orderTitle}>
                      <CommentIcon />
                      Комментарий
                    </div>
                    <div>
                      <textarea
                        id="comment"
                        cols={30}
                        rows={8}
                        placeholder="Дополнительная информация"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comment}
                      />
                    </div>
                  </div>

                  {!isDesktop && (
                    <Checkbox
                      id="agreement"
                      name="agreement"
                      onChange={() =>
                        setFieldValue("agreement", !values.agreement)
                      }
                      checked={values.agreement}
                      error={
                        errors.agreement &&
                        touched.agreement &&
                        errors.agreement
                      }
                    >
                      <span>
                        С <a href="https://lider-truck.ru/agreement/"
                          rel="noreferrer">пользовательским соглашением</a> ознакомлен
                      </span>
                    </Checkbox>
                  )}
                </div>
              </div>

              <div className={styles.total}>
                <div className={styles.totalRowBlock}>
                  <div className={styles.totalRow}>
                    <div className={styles.totalText}>Позиций:</div>
                    <div className={styles.totalQuantity}>
                      {cartQuantity}
                    </div>
                  </div>
                  <div className={styles.totalRow}>
                    <div className={styles.totalTextSumm}>Сумма:</div>
                    <div className={styles.totalSumm}>
                      {priceFormat(cartSumm)} р.
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  className={styles.button}
                  breakpoint={breakpoint}
                >
                  Оформить заказ
                </Button>
                {isDesktop && (
                  <Checkbox
                    id="agreement"
                    name="agreement"
                    onChange={() =>
                      setFieldValue("agreement", !values.agreement)
                    }
                    checked={values.agreement}
                    error={
                      errors.agreement &&
                      touched.agreement &&
                      errors.agreement
                    }
                  >
                    <span>
                      С <a href="https://lider-truck.ru/agreement/"
                        rel="noreferrer">пользовательским соглашением</a> ознакомлен
                    </span>
                  </Checkbox>
                )}
              </div>
            </form>
            <AddressModal className={styles.addressModal}>
              <SelectAddress
                addresses={user?.addresses}
                setShownAddressModal={setShownAddressModal}
                delivery={values.delivery}
                onSelect={(address: TAddress | null) => setAddress(address)}
                address={address}
                breakpoint={breakpoint}
                renderDelivaryArea={renderDelivaryArea}
                renderAddress={renderAddress}
              />
            </AddressModal>
          </Swipable>
        )}
      </Formik >
    }
    <SuccessModal>
      <SuccessOrderModal breakpoint={breakpoint} order={order} />
    </SuccessModal>

  </>;
};
import { FC, useState } from "react";
import { object, string } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "components/Button";
import { Input } from "components/Input";
import { Formik } from "formik";
import useQuery from "hooks/useQuery";
import { newPassword } from "services/userApi";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./newPassword.module.scss";

const NewPasswordSchema = object().shape({
  password: string().required(),
});

export const NewPasswordPage: FC = () => {
  const { breakpoint } = useBreakpoint();
  const query = useQuery(useLocation);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const newPasswordHandler = (
    values: { password: string },
    resetForm: () => void
  ) => {
    if (!query.get("code")) return;
    setLoading(true);

    newPassword({ code: query.get("code")!, password: values.password })
      .then((res) => {
        toast.success("Пароль успешно изменен");
        resetForm();
        navigate("/login");
      })
      .catch((e) => {
        toast.error("Ошибка при восстановлении пароля. Попробуйте позднее.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Новый пароль</div>
      <div className={styles.subTitle}>Придумайте новый пароль</div>

      <Formik
        initialValues={{
          password,
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={(values, { resetForm }) => {
          newPasswordHandler(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              id="password"
              type="password"
              label="Новый пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={styles.input}
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
            <Button
              type="submit"
              className={styles.enter}
              breakpoint={breakpoint}
            >
              Изменить пароль
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

import axios from "axios";

import { axiosInstance, baseUrl } from "./api";

export type TLogin = {
  email: string;
  password: string;
};

export const login = async (props: TLogin) => {
  const data = new URLSearchParams();
  data.append("grantType", "password");
  data.append("username", props.email);
  data.append("password", props.password);

  const res = await axios.post(`${baseUrl}/requestToken`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
  return res.data;
};

export type TLegalForm = {
  email: string;
  password: string;
  firstName: string;
  phone: string;
  orgInn: string;
  orgNameShort: string;
  orgName: string;
  orgAddress: string;
  orgKpp: string;
  orgOgrn: string;
  orgOkpo: string;
  orgRs: string;
  orgKs: string;
  orgBik: string;
  orgBank: string;
  genDir: string;
  type: number;
  agreement: boolean;
};

export type TPrivateForm = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: string;
  type: number;
  agreement: boolean;
};

export type TRegister = TLegalForm | TPrivateForm;

export const registerUser = async (props: TRegister) => {
  const res = await axiosInstance.post("/users.register", props);
  return res.data;
};

export const innSuggestion = async (search: string) => {
  const params = {
    search
  };
  const res = await axiosInstance.get("/users.innSuggestion", { params });
  return res.data;
};

export type TActivateUser = {
  code: string;
};

export const activateUser = async (props: TActivateUser) => {
  const res = await axiosInstance.post("/users.activate", props);
  return res.data;
};

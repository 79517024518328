import { FC } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./oem.module.scss";

export type TCross = {
  id: string;
  article: string;
  brand: string;
  analogueCode: string | null;
};
interface IOem {
  cross: TCross[];
}

export const Oem: FC<IOem> = ({ cross }) => {
  const { breakpoint, isDesktop } = useBreakpoint();
  return (
    <div className={classNames(styles.oem, styles[breakpoint])}>
      {isDesktop && <div className={styles.title}>ОЕМ номера:</div>}
      <ul className={styles.list}>
        {cross.map((item: TCross) => (
          <li className={styles.item} key={item.id}>
            <span className={styles.label}>{item.article}</span>
            <span className={styles.value}>{item.brand}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

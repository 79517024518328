import {
  FC,
  ReactNode,
  Children,
  isValidElement,
  cloneElement,
  ChangeEvent,
} from "react";
import classNames from "classnames";

import styles from "./radio.module.scss";

interface IRadio {
  children: ReactNode[];
  error?: string | false;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  className?: string;
}

interface Details {
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
}

export const Radio: FC<IRadio> = ({
  children,
  error = "",
  onChange,
  name,
  value,
  className
}) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        error,
        onChange,
        name,
        value
      } as Partial<Details>);
    }
    return child;
  });
  return (
    <div className={classNames({ [styles.error]: error }, className)}>
      {childrenWithProps}
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import styles from "./closeButton.module.scss";

interface ICloseButton {
  onClick?: () => void;
  className?: string;
}

export const CloseButton: FC<ICloseButton> = ({ onClick, className }) => {
  return (
    <button
      title="Close (Esc)"
      type="button"
      className={classNames(styles.close, className)}
      onClick={onClick}
    >
      ×
    </button>
  );
};

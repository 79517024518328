import { FC } from "react";
import classNames from "classnames";

import styles from "./spinner.module.scss";

interface ISpinner2 {
  size?: number;
  overlay?: boolean;
  className?: string;
}

export const Spinner2: FC<ISpinner2> = ({ size = 40, overlay, className }) => (
  <div
    className={classNames(
      styles.spinner,
      { [styles.overlay]: overlay },
      className
    )}
  >
    <div
      className={styles["spinner-border"]}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <span className={styles["sr-only"]}>Loading...</span>
    </div>
  </div>
);

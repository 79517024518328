import { FC, useState } from "react";
import classNames from "classnames";

import { Tabs } from "components/Tabs";
import { TRegister, registerUser } from "services/authApi";
import { Spinner2 } from "components/Spinner2";
import { RModal } from "components/RModal";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./register.module.scss";
import { PrivateForm } from "./PrivateForm";
import { LegalForm } from "./LegalForm";

import { RegisterResult } from "./RegisterResult";

export const RegisterPage: FC = () => {
  const { breakpoint } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [shownModalResult, setShownModalResult] = useState(false);

  const registerHandler = (values: TRegister, resetForm: () => void) => {
    setLoading(true);
    registerUser(values)
      .then((res) => {
        resetForm();
        setShownModalResult(true);
      })
      .catch((e) => {
        alert(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.register, styles[breakpoint])}>
      <div className={styles.container}>
        <div className={styles.title}>Регистрация</div>
        <div className={styles.subTitle}>Введите свои данные</div>
        {loading && <Spinner2 overlay />}
        <Tabs
          tabs={["Для юридических лиц", "Для физических лиц"]}
          className={styles.tabs}
        >
          <LegalForm registerHandler={registerHandler} />
          <PrivateForm registerHandler={registerHandler} />
        </Tabs>
        <RModal shown={shownModalResult} setShown={setShownModalResult}>
          <RegisterResult setShownModalResult={setShownModalResult} breakpoint={breakpoint} />
        </RModal>
      </div>
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./characteristics.module.scss";

export type TCharacteristic = {
  id: string;
  name: string;
  unit: string;
  value: string;
};
interface ICharacteristics {
  characteristics: TCharacteristic[];
}

export const Characteristics: FC<ICharacteristics> = ({ characteristics }) => {
  const { breakpoint, isDesktop } = useBreakpoint();
  return (
    <div className={classNames(styles.characteristics, styles[breakpoint])}>
      {isDesktop && <div className={styles.title}>Характеристики:</div>}
      <ul className={styles.list}>
        {characteristics.map((item: TCharacteristic) => (
          <li className={styles.item} key={item.id}>
            <span className={styles.label}>
              {item.name}
              {item.unit && ","} {item.unit}
            </span>
            <span className={styles.value}>{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

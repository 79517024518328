import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { useBreakpoint } from "hooks/useBreakpoint";
import { PageHeader } from "components/PageHeader";
import { ProductInfo } from "components/ProductInfo";
import { useCart } from "context/CartContext";
import { ButtonSmall } from "components/ButtonSmall";
import { FavoriteButton } from "components/FavoriteButton";
import { getFavorite, removeFavorite } from "services/favoriteApi";
import { useUser } from "context/UserContext";
import { TNomenclature } from "pages/OrderOne";
import { Spinner } from "components/Spinner";
import { MainBlock } from "layout/MainBlock";
import { RightSidebar } from "layout/RightSidebar";
import { Body } from "layout/Body";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { useSearch } from "context/SearchContext";
import { useProductModal } from "hooks/useProductModal";
import { ProductModal } from "components/ProductModal";
import { ReactComponent as CartIcon } from "assets/img/svg/cart.svg";

import styles from "./favorite.module.scss";

export type TFavorite = {
  id: string;
  nomenclature: TNomenclature;
};

export const FavoritePage: FC = () => {
  const [loading, setLoading] = useState(false);
  const { breakpoint, isDesktop } = useBreakpoint();
  const { favorite, setFavorite } = useCart();
  const { setArticle, setBrand } = useSearch();
  const { ProdModal } = useProductModal();
  const { _, setNomenclatureId, nomenclatureId } = useSearch();
  const { user } = useUser();

  const getAndSetFavorite = () => {
    setLoading(true);
    getFavorite()
      .then(setFavorite)
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  const removeHandler = (id: string) => {
    if (!id) return;
    setLoading(true);
    removeFavorite(id)
      .then(getAndSetFavorite)
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => { });
  };

  useEffect(() => {
    if (!user) return;
    getAndSetFavorite();
  }, [user]);

  const searchHandler = (article: string, brand: string) => {
    setArticle(article);
    setBrand(brand);
  };

  return (
    <>
      <BreadCrumbs data={[{ title: "Избранное" }]} />
      <Helmet>
        <title>Избранное</title>
      </Helmet>
      <div className={classNames(styles.favorite, styles[breakpoint])}>
        {loading && <Spinner />}
        <Body>
          <PageHeader title="Избранное" />
          <MainBlock>
            {!favorite.length && (
              <div className={styles.noFavorite}>
                <div className={styles.noFavoriteBlock}>
                  <div className={styles.noFavoriteImage} />
                  <div className={styles.noFavoriteTitle}>
                    Нет избранных товаров
                  </div>
                  <div className={styles.noFavoriteDescription}>
                    Тут будут отображаться товары, добавленные в избранное
                  </div>
                </div>
              </div>
            )}

            {favorite.map((item: TFavorite) => (
              <div className={styles.item} key={item.id}>
                <ProductInfo
                  article={item.nomenclature?.article}
                  brand={item.nomenclature?.brand}
                  name={item.nomenclature?.name}
                  nomenclatureId={item.nomenclature?.id}
                  image={item.nomenclature?.image}
                  breakpoint={breakpoint}
                />
                <div className={styles.buttons}>
                  <ButtonSmall
                    onClick={() =>
                      searchHandler(
                        item.nomenclature?.article,
                        item.nomenclature?.brand
                      )
                    }
                    breakpoint={breakpoint}
                  >
                    {isDesktop ? `Поиск` : <CartIcon />}
                  </ButtonSmall>
                  <FavoriteButton
                    className={styles.favoriteButton}
                    onClick={() => removeHandler(item.id)}
                    active
                  />
                </div>
              </div>
            ))}
          </MainBlock>
        </Body>
        {isDesktop && <RightSidebar />}
      </div>
      <ProdModal>
        <ProductModal
          nomenclatureId={nomenclatureId}
          setNomenclatureId={setNomenclatureId}
          breakpoint={breakpoint}
        />
      </ProdModal>
    </>
  );
};

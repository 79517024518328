import { axiosInstance } from "./api";

export type TAddFavorite = {
  nomenclature: string;
};

export const addFavorite = async (props: TAddFavorite) => {
  const res = await axiosInstance.post("/api/v2/markedPart", props);
  return res.data;
};

export const getFavorite = async () => {
  const res = await axiosInstance.get("/api/v2/markedPart");
  return res.data;
};

export const removeFavorite = async (id: string) => {
  const res = await axiosInstance.delete(`/api/v2/markedPart/${id}`);
  return res.data;
};

import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { ProductInfo, TProduct } from "components/ProductInfo";
import { QuantityIcon } from "components/QuantityIcon";
import { Counter } from "components/Counter";
import { FavoriteButton } from "components/FavoriteButton";
import { Button } from "components/Button";
import { Related } from "components/Related";
import { useCart } from "context/CartContext";
import { priceFormat } from "utils/format";
import { count, summ } from "utils/cart";
import { addFavorite } from "services/favoriteApi";
import { cartUpdate } from "services/cartApi";
import { TCart } from "pages/Cart";

import styles from "./addToCartModal.module.scss";

interface ICartModal {
  product: TProduct;
  setShown: (shown: boolean) => void;
  breakpoint?: string;
}

export const AddToCartModal: FC<ICartModal> = ({
  //product,
  setShown,
  breakpoint = "desktop"
}) => {
  const { cart, added, setCart, setCartLoading } = useCart();

  useEffect(() => {
    console.log(added);
  }, [added]);

  const getQuantity = () => {
    const cartItem = cart.find((item: TCart) => item.id === added.id);
    if (!cartItem) return added.quantity;
    return cartItem.count;
  };

  const addFavoriteHandler = (product: TProduct) => {
    if (!product.nomenclatureId) return;
    addFavorite({ nomenclature: product.nomenclatureId })
      .then()
      .catch()
      .finally();
  };

  const updateCart = (id: string, count: number) => {
    cartUpdate({ id, count })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      <div className={styles.cartBlock}>
        <div className={styles.title}>Товар добавлен в корзину</div>
        <div className={styles.product}>
          <div className={styles.info}>
            <ProductInfo
              article={added?.article}
              brand={added?.brand}
              name={added?.name}
              product={added}
              image={added?.image}
              nomenclatureId={added?.nomenclatureId}
              className={styles.productInfo}
              breakpoint={breakpoint}
            />
          </div>

          {breakpoint === "desktop" ? (
            <>
              <QuantityIcon exist={added?.exist}>
                {added.deliveryTime}
              </QuantityIcon>
              <div className={styles.quantity}>{added?.exist} шт.</div>
              <div className={styles.priceBlock}>
                <div className={styles.price}>
                  {priceFormat(added.price)} р.
                </div>
                <Counter
                  max={added?.exist || "9999"}
                  quantity={getQuantity()}
                  setQuantity={(value: number) => updateCart(added.id, value)}
                  breakpoint={breakpoint}
                />
                <FavoriteButton onClick={() => addFavoriteHandler(added)} />
              </div>
            </>
          ) : (
            <div className={styles.bottomBlock}>
              <div className={styles.leftBlock}>
                <FavoriteButton onClick={() => addFavoriteHandler(added)} />
                <div className={styles.quantity}>{added.exist} шт.</div>
                <QuantityIcon exist={added.exist}>
                  <div className={styles.priceBlock}>
                    <div className={styles.price}>
                      {priceFormat(added.price)} р.
                    </div>
                    <div className={styles.delivery}>{added.deliveryTime}</div>
                  </div>
                </QuantityIcon>
              </div>
              <Counter
                max={added.exist || "9999"}
                quantity={getQuantity()}
                setQuantity={(value: number) => updateCart(added.id, value)}
                breakpoint={breakpoint}
              />
            </div>
          )}
        </div>
        <div className={styles.productName}>{added.name}</div>

        {breakpoint === "desktop" && (
          <div className={styles.checkoutBlock}>
            <div>
              <div>В корзине</div>
              <Link to="/cart" className={styles.cartLink}>
                товаров: {count(cart)} на сумму {priceFormat(summ(cart))} р.
              </Link>
            </div>
            <div className={styles.buttonGroup}>
              <Button
                className={styles.outlineButton}
                onClick={() => setShown(false)}
                breakpoint={breakpoint}
              >
                Продолжить покупки
              </Button>
              <Link className={styles.button} to="/cart">
                Оформить заказ
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className={styles.relatedBlock}>
        {added?.relatedProducts?.length > 0 && (
          <Related products={added?.relatedProducts} />
        )}
      </div>

      {breakpoint !== "desktop" && (
        <div className={styles.checkoutBlock}>
          <div className={styles.inCart}>
            В корзине
            <Link to="/cart" className={styles.cartLink}>
              товаров: {count(cart)} на сумму {priceFormat(summ(cart))} р.
            </Link>
          </div>
          <div className={styles.buttonBlock}>
            <Link className={styles.button} to="/cart">
              Оформить заказ
            </Link>
            <Button
              className={styles.outlineButton}
              onClick={() => setShown(false)}
              breakpoint={breakpoint}
            >
              Продолжить покупки
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
